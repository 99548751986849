import {redirect, useActionData, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import ProductForm, {formDataToProduct} from "./ProductForm";
import Products from "../../../services/UkTreatmentApi/Products";
import AlertContext from "../../../contexts/AlertContext";
import {useContext, useEffect} from "react";

export async function action({request}) {
    const formData = await request.formData();
    const product = formDataToProduct(formData);
    product.categoryId = product.category.id;

    try {
        await Products.create(product);

        return redirect("/manage/products");
    } catch (error) {
        return error;
    }
}

const Create = () => {
    const {categories} = useRouteLoaderData("manageProducts");
    const {handleErrorAlert} = useContext(AlertContext);
    const actionError = useActionData();

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    const text = `Register the product in the app. 
Assign the correct category.`;

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Product" text={text}/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <ProductForm categories={categories} id="create-product-form"/>
        </div>
    </FormContainer>;
}

export default Create;