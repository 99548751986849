import {createColumnHelper} from "@tanstack/react-table";
import {useNavigate, useRouteLoaderData} from "react-router-dom";
import Container from "../../../components/Layout/Container";
import DataTable from "../../../components/DataTable/DataTable";
import moment from "moment-timezone";
import {default as ApiCullingReasons} from "../../../services/UkTreatmentApi/CullingReasons";
import {orderBy} from "lodash";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor("cowNumber", {
        header: "Cow Number",
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor(value => moment(value.cullingDate).format("YYYY-MM-DD"), {
        header: "Culling Date",
        sortingFn: "datetime",
    }),
    columnHelper.accessor(value => value.reason === "Other" ? value.otherReason : value.reason, {
        header: "Reason",
    }),
];

export async function loader({params}) {
    const cullingReasons = await ApiCullingReasons.ofFarm(params.farmUid)
        .then(({data}) => orderBy(data, ["cullingDate", "cowNumber"], ["desc", "asc"]));

    const getCullingReason = (id) => {
        const cullingReason = cullingReasons.find(r => r.id === parseInt(id));
        if (!cullingReason) throw new Error(`Couldn't find culling reason with id \`${id}\``);

        return cullingReason;
    }

    return {cullingReasons, getCullingReason};
}

const CullingReasons = () => {
    const {cullingReasons} = useRouteLoaderData("farmCullingReasons");
    const navigate = useNavigate();

    return <Container>
        <DataTable title="Culling Reasons" data={cullingReasons} columns={columns}
                   onCreateClick={() => navigate("create")} onRowClick={(row) => navigate(row.id.toString())}/>
    </Container>;
}

export default CullingReasons;