import * as yup from "yup";
import {useFetcher} from "react-router-dom";
import {FieldArray, Formik} from "formik";
import moment from "moment-timezone";
import BasicPrimaryButton from "../../../components/Button/BasicPrimaryButton";
import FormikDatePicker from "../../../components/Input/FormikDatePicker";
import MultipleInputHeader from "../../../components/Input/MultipleInputHeader";
import FormikMultipleTextInput from "../../../components/Input/FormikMultipleTextInput";
import FormikTextInput from "../../../components/Input/FormikTextInput";
import FormikError from "../../../components/Input/FormikError";
import ProductUnitInput from "../../../components/Input/ProductUnitInput";
import AlertContext from "../../../contexts/AlertContext";
import {useContext, useEffect} from "react";
import FormikSelect from "../../../components/Input/FormikSelect";
import FormikSelectWithCross from "../../../components/Input/FormikSelectWithCross";

const productCategorySchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
});
const productSchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
    defaultUnit: yup.string(),
    category: productCategorySchema,
    retired: yup.boolean(),
});
const reasonCategorySchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
});
const reasonSchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
    category: reasonCategorySchema,
    retired: yup.boolean(),
});
const treatmentSchema = yup.object({
    product: productSchema
        .required("Product is required"),
    dosage: yup.number().positive().integer()
        .required("Dosage is required"),
    unit: yup.string().trim()
        .required("Unit is required"),
});
const reactiveTreatmentsSchema = yup.object({
    id: yup.number().positive().integer(),
    program: yup.string().required(),
    farmUid: yup.string().required(),
    treatmentDate: yup.number().positive().integer()
        .required("Treatment Date is required"),
    treatments: yup.array().of(treatmentSchema)
        .required("Treatments are required")
        .min(1, "At least one Treatment is required"),
    cowNumbers: yup.array().of(
        yup.string().required("Cow Number is required")
    )
        .required("Cow Numbers are required")
        .min(1, "At least one Cow Number is required"),
    reason: reasonSchema.default(null).required("Reason is required"),
    comments: yup.string().trim()
        .max(511, "Must be 511 characters or less"),
});

export function formDataToReactiveTreatments(formData) {
    const reactiveTreatments = Object.fromEntries(formData);
    reactiveTreatments.cowNumbers = JSON.parse(reactiveTreatments.cowNumbers);
    reactiveTreatments.treatments = JSON.parse(reactiveTreatments.treatments);
    reactiveTreatments.reason = JSON.parse(reactiveTreatments.reason);

    if (!reactiveTreatments.reason) delete reactiveTreatments.reason;

    return reactiveTreatmentsSchema.cast(reactiveTreatments);
}

const ReactiveTreatmentsCreateForm = ({farm, products, reasons, ...props}) => {
    const fetcher = useFetcher();
    const {addAlert, handleErrorAlert} = useContext(AlertContext);

    useEffect(() => {
        const result = fetcher.data?.result;
        if (result) addAlert("success", "Submission successful",
            `Added ${result.length} treatment${result.length === 1 ? "" : "s"}`);
        if (fetcher.data?.error) handleErrorAlert(fetcher.data.error, "Submission failed");
    }, [fetcher.data, addAlert, handleErrorAlert]);

    return <Formik
        initialValues={{
            program: "Health Challenges",
            farmUid: farm.uid,
            treatmentDate: moment().valueOf(),
            treatments: [{
                product: null,
                dosage: 1,
                unit: "",
            }],
            cowNumbers: [
                "",
            ],
            reason: null,
            comments: "",
        }}
        validationSchema={reactiveTreatmentsSchema}
        onSubmit={async values => fetcher.submit({
            ...values,
            treatments: JSON.stringify(values.treatments),
            cowNumbers: JSON.stringify(values.cowNumbers),
            reason: JSON.stringify(values.reason),
        }, {method: "post"})}
    >
        {formik => <form className="px-4 sm:px-6" method="post" onSubmit={formik.handleSubmit} {...props}>
            <div className="grid grid-cols-12 gap-y-6 gap-x-2 sm:gap-6">
                <div className="col-span-12">
                    <FormikDatePicker label="Treatment Date" name="treatmentDate"/>
                </div>

                <div className="col-span-12">
                    <FieldArray name="treatments">
                        {({push, remove}) =>
                            <div className="grid grid-cols-12 gap-y-6 gap-x-2 sm:gap-6">
                                <div className="col-span-12">
                                    <MultipleInputHeader
                                        header="Treatments"
                                        addOnClick={() => push({
                                            product: null,
                                            dosage: 1,
                                            unit: "",
                                        })}
                                    />
                                    <FormikError name="treatments"/>
                                </div>

                                {formik.values.treatments.map((treatment, index) => [
                                    <div key={`${index}-1`} className="col-span-12 sm:col-span-6">
                                        <FormikSelectWithCross
                                            name={`treatments.${index}.product`} label="Product"
                                            groupKey="category.name"
                                            options={products} optionValue="id" optionLabel="name"
                                            onCrossClick={() => remove(index)}
                                        />
                                    </div>,
                                    <div key={`${index}-2`} className="col-span-6 sm:col-span-3">
                                        <FormikTextInput
                                            label="Dosage" name={`treatments.${index}.dosage`}
                                            type="number" min={1}
                                        />
                                    </div>,
                                    <div key={`${index}-3`} className="col-span-6 sm:col-span-3">
                                        <ProductUnitInput
                                            label="Unit" name={`treatments.${index}.unit`} type="text"
                                            productValue={formik.values.treatments[index].product}
                                        />
                                    </div>,
                                ]).flat()}
                            </div>
                        }
                    </FieldArray>
                </div>

                <div className="col-span-12">
                    <FieldArray name="cowNumbers">
                        {({push, remove}) =>
                            <div className="grid grid-cols-12 gap-y-6 gap-x-2 sm:gap-6">
                                <div className="col-span-12">
                                    <MultipleInputHeader header="Cow Numbers" addOnClick={() => push("")}/>
                                    <FormikError name="cowNumbers"/>
                                </div>

                                {formik.values.cowNumbers.map((cowNumber, index) =>
                                    <div key={index} className="col-span-6 sm:col-span-4">
                                        <FormikMultipleTextInput
                                            label="Cow Number" name={`cowNumbers.${index}`} type="text"
                                            deleteOnClick={() => remove(index)}
                                        />
                                    </div>
                                )}
                            </div>
                        }
                    </FieldArray>
                </div>

                <div className="col-span-12">
                    <FormikSelect
                        name="reason" label="Reason" options={reasons} optionValue="id" optionLabel="name"
                        groupKey="category.name"
                    />
                </div>

                <div className="col-span-12">
                    <FormikTextInput label="Comments" name="comments" as="textarea"/>
                </div>
            </div>

            <div className="mt-4 py-3">
                <BasicPrimaryButton disabled={fetcher.state === "submitting"} type="submit">
                    Submit
                </BasicPrimaryButton>
            </div>
        </form>}
    </Formik>;
}

export default ReactiveTreatmentsCreateForm;