import {useField} from "formik";
import BasicLabel from "./BasicLabel";
import BasicInput from "./BasicInput";
import FormikError from "./FormikError";

const FormikTextInput = ({label, ...props}) => {
    const [field] = useField(props);

    return <>
        <BasicLabel htmlFor={props.id || props.name}>{label}</BasicLabel>
        <BasicInput {...field} {...props}/>
        <FormikError {...props}/>
    </>;
}

export default FormikTextInput;