import Container from "../components/Layout/Container";
import {useNavigate, useRouteLoaderData} from "react-router-dom";
import {createColumnHelper} from "@tanstack/react-table";
import DataTable from "../components/DataTable/DataTable";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor("uid", {
        header: "UID",
    }),
    columnHelper.accessor("name", {
        header: "Farm Name",
    }),
    columnHelper.accessor("address", {
        header: "Address",
    }),
    columnHelper.accessor(value => value.users.map(u => u.name).join(", "), {
        header: "Users",
    }),
];

const Root = () => {
    const {farms} = useRouteLoaderData("root");
    const navigate = useNavigate();

    return <Container>
        <DataTable title="My farms" data={farms} columns={columns} useFilter={true}
                   onRowClick={(row) => navigate(`/farms/${row.uid}`)}/>
    </Container>;
}

export default Root;