import {destroy, get, post, put} from "./base";

const ReasonCategories = {
    index: () => get("/reason_categories"),
    create: (body) => post("/reason_categories", body),
    single: (id) => get(`/reason_categories/${id}`),
    update: (id, body) => put(`/reason_categories/${id}`, body),
    delete: (id) => destroy(`/reason_categories/${id}`),
};

export default ReasonCategories;