import {destroy, get, post, put} from "./base";

const ProductPlans = {
    index: () => get("/product_plans"),
    create: (body) => post("/product_plans", body),
    single: (id) => get(`/product_plans/${id}`),
    update: (id, body) => put(`/product_plans/${id}`, body),
    delete: (id) => destroy(`/product_plans/${id}`),
};

export default ProductPlans;