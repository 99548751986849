import {redirect, useActionData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import CowStageForm, {formDataToCowStage} from "./CowStageForm";
import CowStages from "../../../services/UkTreatmentApi/CowStages";
import {useContext, useEffect} from "react";
import AlertContext from "../../../contexts/AlertContext";

export async function action({request}) {
    const formData = await request.formData();
    const cowStage = formDataToCowStage(formData);

    try {
        await CowStages.create(cowStage);

        return redirect("/manage/cow_stages");
    } catch (error) {
        return error;
    }
}

const Create = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const actionError = useActionData();

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Cow Stage" text="Register the cow stage in the app."/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <CowStageForm id="create-cow-stage-form"/>
        </div>
    </FormContainer>;
}

export default Create;