import {destroy, get, post, put} from "./base";

const TreatmentReasons = {
    index: () => get("/treatment_reasons"),
    create: (body) => post("/treatment_reasons", body),
    single: (id) => get(`/treatment_reasons/${id}`),
    update: (id, body) => put(`/treatment_reasons/${id}`, body),
    delete: (id) => destroy(`/treatment_reasons/${id}`),
};

export default TreatmentReasons;