import {redirect, useActionData, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import ProductPlansCreateForm, {formDataToProductPlan} from "./ProductPlansCreateForm";
import {useContext, useEffect} from "react";
import AlertContext from "../../../contexts/AlertContext";
import ProductPlans from "../../../services/UkTreatmentApi/ProductPlans";

export async function action({request}) {
    const formData = await request.formData();
    const productPlan = formDataToProductPlan(formData);
    const plans = productPlan.treatments.map(treatment => ({
        cowStage: productPlan.cowStage,
        cowStageId: productPlan.cowStage.id,
        product: treatment.product,
        productId: treatment.product.id,
        dosage: treatment.dosage,
        unit: treatment.unit,
    }));

    try {
        await ProductPlans.create({plans});

        return redirect("/manage/product_plans");
    } catch (error) {
        return error;
    }
}

const Create = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {cowStages, products} = useRouteLoaderData("manageProductPlans");
    const actionError = useActionData();

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Product Plan" text="Register the product plan in the app."/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <ProductPlansCreateForm cowStages={cowStages} products={products} id="create-product-plan-form"/>
        </div>
    </FormContainer>;
}

export default Create;