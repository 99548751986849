import Select from "react-select";
import {useMemo} from "react";
import {groupBy} from "lodash";
import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";

const EmotionCacheProvider = ({children}) => {
    const cache = useMemo(() => createCache({
        key: "with-tailwind",
        insertionPoint: document.querySelector("title"),
    }), []);

    return <CacheProvider value={cache}>{children}</CacheProvider>;
}

const BasicSelect = ({optionLabel, optionValue, groupKey, options, ...props}) => {
    const finalOptions = useMemo(() => {
        if (groupKey) {
            const groups = groupBy(options, groupKey);

            return Object.keys(groups).sort().map(group => ({
                label: group,
                options: groups[group],
            }));
        } else return options;
    }, [options, groupKey]);

    return <EmotionCacheProvider>
        <Select
            options={finalOptions}
            placeholder=""
            getOptionLabel={(o) => o[optionLabel]}
            getOptionValue={(o) => o[optionValue]}
            closeMenuOnSelect={!props.isMulti}
            menuPlacement="auto"
            className="mt-1 sm:text-sm"
            classNames={{
                control: ({isFocused}) =>
                    `shadow-sm bg-white dark:bg-gray-800 ${isFocused
                        ? "border-cyan-500 dark:border-cyan-400 hover:border-cyan-500 dark:hover:border-cyan-400"
                        : "border-gray-200 dark:border-gray-600 hover:border-gray-300 dark:hover:border-gray-400"}`,
                input: () => "dark:text-white",
                menu: () => `shadow-none bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600`,
                multiValue: () => "rounded bg-green-50 dark:bg-gray-700",
                multiValueLabel: () => "dark:text-white",
                multiValueRemove: () => "hover:bg-green-50 dark:hover:bg-gray-700 text-red-400 dark:text-red-600 " +
                    "hover:text-red-600 dark:hover:text-red-400",
                option: ({isSelected, isFocused}) =>
                    `text-gray-700 ${isSelected || isFocused
                        ? "bg-green-500 text-white dark:bg-gray-900 dark:text-green-400"
                        : "dark:text-white"} ${isSelected ? "italic" : ""}`,
                singleValue: () => "dark:text-white",
            }}
            {...props}
        />
    </EmotionCacheProvider>;
}

export default BasicSelect;