import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import AntibioticUsageForm, {formDataToAntibioticUsage} from "./AntibioticUsageForm";
import FormContainer from "../../../components/Layout/FormContainer";
import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";
import Treatments from "../../../services/UkTreatmentApi/Treatments";

export async function action({request, params}) {
    const formData = await request.formData();
    const antibioticUsage = formDataToAntibioticUsage(formData);
    antibioticUsage.productId = antibioticUsage.product.id;
    antibioticUsage.reasonId = antibioticUsage.reason?.id;

    try {
        await Treatments.update(params.treatmentId, antibioticUsage);

        return redirect(`/farms/${params.farmUid}/antibiotic_usages`);
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {products, reasons, getAntibioticUsage} = useRouteLoaderData("farmAntibioticUsages");
    const {treatmentId} = useParams();
    const actionError = useActionData();
    const {farm} = useRouteLoaderData("currentFarm");

    const antibioticUsage = useMemo(() => getAntibioticUsage(treatmentId), [getAntibioticUsage, treatmentId]);

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Antibiotic Usage" text="Edit the antibiotic usage."/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <AntibioticUsageForm farm={farm} antibioticUsage={antibioticUsage} products={products} reasons={reasons}
                                 id="edit-antibiotic-usage-form"/>
        </div>
    </FormContainer>;
}

export default Update;