import {createColumnHelper} from "@tanstack/react-table";
import {useNavigate, useRouteLoaderData} from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import Container from "../../../components/Layout/Container";
import {default as ApiReasonCategories} from "../../../services/UkTreatmentApi/ReasonCategories";
import {sortBy} from "lodash";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor("id", {
        header: "ID",
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("name", {
        header: "Category Name",
    }),
];

export async function loader() {
    const reasonCategories = await ApiReasonCategories.index().then(({data}) => sortBy(data, ["name"]));
    
    const getReasonCategory = (id) => {
        const reasonCategory = reasonCategories.find(c => c.id === parseInt(id));
        if (!reasonCategory) throw new Error(`Couldn't find reason category with id \`${id}\``);

        return reasonCategory;
    }

    return {reasonCategories, getReasonCategory};
}

const ReasonCategories = () => {
    const {reasonCategories} = useRouteLoaderData("manageReasonCategories");
    const navigate = useNavigate();

    return <Container>
        <DataTable title="Reason Categories" data={reasonCategories} columns={columns} useFilter={true}
                   onCreateClick={() => navigate("create")} onRowClick={(row) => navigate(row.id.toString())}/>
    </Container>;
}

export default ReasonCategories;