import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import ProductPlanUpdateForm, {formDataToProductPlan} from "./ProductPlanUpdateForm";
import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";
import ProductPlans from "../../../services/UkTreatmentApi/ProductPlans";

export async function action({request, params}) {
    const formData = await request.formData();
    const productPlan = formDataToProductPlan(formData);
    productPlan.cowStageId = productPlan.cowStage.id;
    productPlan.productId = productPlan.product.id;

    try {
        await ProductPlans.update(params.productPlanId, productPlan);

        return redirect("/manage/product_plans");
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {cowStages, products, getProductPlan} = useRouteLoaderData("manageProductPlans");
    const {productPlanId} = useParams();
    const actionError = useActionData();

    const productPlan = useMemo(() => getProductPlan(productPlanId), [getProductPlan, productPlanId]);

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission");
    }, [actionError, handleErrorAlert]);

    const text = `Edit the product plan. 

Delete if you accidentally created this and nothing else references it. (Else you have to delete all that references it)`;

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Product Plan" text={text}/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <ProductPlanUpdateForm productPlan={productPlan} cowStages={cowStages} products={products}
                                   id="edit-product-plan-form"/>
        </div>
    </FormContainer>;
}

export default Update;