import {useEffect} from "react";
import useLocalStorage from "./useLocalStorage";

function useDarkSide() {
    const [theme, setTheme] = useLocalStorage("theme", "light");
    const colorTheme = theme === "dark" ? "light" : "dark";

    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove(colorTheme);
        root.classList.add(theme);
    }, [theme, colorTheme]);

    return [colorTheme, setTheme]
}

export default useDarkSide;