import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FertilityPerformanceForm, {formDataToFertilityPerformance} from "./FertilityPerformanceForm";
import FormContainer from "../../../components/Layout/FormContainer";
import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";
import FertilityPerformances from "../../../services/UkTreatmentApi/FertilityPerformances";

export async function action({request, params}) {
    const formData = await request.formData();
    const fertilityPerformance = formDataToFertilityPerformance(formData);

    try {
        await FertilityPerformances.update(params.fertilityPerformanceId, fertilityPerformance);

        return redirect(`/farms/${params.farmUid}/fertility_performances`);
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {getFertilityPerformance} = useRouteLoaderData("farmFertilityPerformances");
    const {fertilityPerformanceId} = useParams();
    const actionError = useActionData();
    const {farm} = useRouteLoaderData("currentFarm");

    const fertilityPerformance = useMemo(() => {
        return getFertilityPerformance(fertilityPerformanceId);
    }, [getFertilityPerformance, fertilityPerformanceId]);

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission");
    }, [actionError, handleErrorAlert]);

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Fertility Performance" text="Edit the fertility performance."/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <FertilityPerformanceForm farm={farm} fertilityPerformance={fertilityPerformance}
                                      id="edit-fertility-performance-form"/>
        </div>
    </FormContainer>;
}

export default Update;