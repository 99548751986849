import {useField} from "formik";
import BasicLabel from "./BasicLabel";
import BasicInput from "./BasicInput";
import {XMarkIcon} from "@heroicons/react/24/outline";
import FormikError from "./FormikError";

const FormikMultipleTextInput = ({label, deleteOnClick, ...props}) => {
    const [field] = useField(props);

    return <>
        <div className="flex justify-between items-center">
            <BasicLabel htmlFor={props.id || props.name}>{label}</BasicLabel>
            <XMarkIcon onClick={deleteOnClick} className="h-4 w-4 cursor-pointer text-red-400 dark:text-red-600
            hover:text-red-600 dark:hover:text-red-400"/>
        </div>
        <BasicInput {...field} {...props}/>
        <FormikError {...props}/>
    </>;
}

export default FormikMultipleTextInput;