import {Link} from "react-router-dom";

const LinkTile = ({item}) =>
    <Link
        to={item.to}
        className="group rounded-2xl px-4 py-8 sm:p-8 shadow border
        hover:bg-green-50 hover:border-green-400 bg-white
        dark:border-gray-500 dark:hover:bg-gray-900 dark:hover:border-green-400 dark:bg-gray-800 dark:text-white"
    >
        <h3 className="mt-2 font-semibold text-gray-900 dark:text-white dark:group-hover:text-green-400">
            {item.title}
        </h3>

        <p className="mt-2 text-gray-700 dark:text-gray-400 dark:group-hover:text-green-400">
            {item.text}
        </p>
    </Link>

export default LinkTile;