import {createColumnHelper} from "@tanstack/react-table";
import {useNavigate, useRouteLoaderData} from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import Container from "../../../components/Layout/Container";
import {default as ApiProductCategories} from "../../../services/UkTreatmentApi/ProductCategories";
import {sortBy} from "lodash";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor("id", {
        header: "ID",
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("name", {
        header: "Category Name",
    }),
];

export async function loader() {
    const productCategories = await ApiProductCategories.index().then(({data}) => sortBy(data, ["name"]));

    const getProductCategory = (id) => {
        const productCategory = productCategories.find(c => c.id === parseInt(id));
        if (!productCategory) throw new Error(`Couldn't find product category with id \`${id}\``);

        return productCategory;
    }

    return {productCategories, getProductCategory};
}

const ProductCategories = () => {
    const {productCategories} = useRouteLoaderData("manageProductCategories");
    const navigate = useNavigate();

    return <Container>
        <DataTable title="Product Categories" data={productCategories} columns={columns} useFilter={true}
                   onCreateClick={() => navigate("create")} onRowClick={(row) => navigate(row.id.toString())}/>
    </Container>;
}

export default ProductCategories;