import {useField, useFormikContext} from "formik";
import BasicLabel from "./BasicLabel";
import FormikError from "./FormikError";
import BasicSwitch from "./BasicSwitch";

const FormikSwitch = (props) => {
    const [field] = useField(props);
    const {setFieldValue} = useFormikContext();

    return <div className="flex-col align-center">
        <BasicLabel htmlFor={props.id || props.name}>{props.label}</BasicLabel>
        <BasicSwitch checked={field.value} value={field.value} onChange={value => setFieldValue(props.name, value)}
                     {...props}/>
        <FormikError name={props.name}/>
    </div>;
}

export default FormikSwitch;