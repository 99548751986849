import {redirect, useActionData, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FarmForm, {formDataToFarm} from "./FarmForm";
import FormContainer from "../../../components/Layout/FormContainer";
import AlertContext from "../../../contexts/AlertContext";
import {useContext, useEffect} from "react";
import Farms from "../../../services/UkTreatmentApi/Farms";

export async function action({request}) {
    const formData = await request.formData();
    const farm = formDataToFarm(formData);

    try {
        await Farms.create(farm);

        return redirect("/manage/farms");
    } catch (error) {
        return error;
    }
}

const Create = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {users} = useRouteLoaderData("root");
    const actionError = useActionData();

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    const text = `Register the farm in the app. 
Assigned users will be able to see them in their farm list.
Admins already have access to each farm.`;

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Farm" text={text}/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <FarmForm users={users} id="create-farm-form"/>
        </div>
    </FormContainer>;
}

export default Create;