import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FarmForm, {formDataToFarm} from "./FarmForm";
import FormContainer from "../../../components/Layout/FormContainer";
import Farms from "../../../services/UkTreatmentApi/Farms";
import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";

export async function action({request, params}) {
    const formData = await request.formData();
    const farm = formDataToFarm(formData);

    try {
        await Farms.update(params.farmUid, farm);

        return redirect("/manage/farms");
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {users, getFarm} = useRouteLoaderData("root");
    const {farmUid} = useParams();
    const actionError = useActionData();

    const farm = useMemo(() => getFarm(farmUid), [getFarm, farmUid]);

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    const text = `Edit the farm. 
Assigned users will be able to see them in their farm list.
Admins already have access to each farm.

Automatically imported farms cannot be updated.
Delete if you accidentally created this and nothing else references it. (Else you have to delete all that references it)`;

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Farm" text={text}/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <FarmForm farm={farm} users={users} id="edit-farm-form"/>
        </div>
    </FormContainer>;
}

export default Update;