import {createColumnHelper} from "@tanstack/react-table";
import moment from "moment-timezone";
import {useNavigate} from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor(value => moment(value.created).format("YYYY-MM-DD hh:mm"), {
        header: "Created",
        sortingFn: "datetime",
    }),
    columnHelper.accessor("cowNumber", {
        header: "Cow Number",
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor(value => moment(value.treatmentDate).format("YYYY-MM-DD"), {
        header: "Date",
        sortingFn: "datetime",
    }),
    columnHelper.accessor("product.name", {
        header: "Product",
    }),
    columnHelper.accessor("dosage", {
        header: "Dosage",
        cell: info => `${info.getValue()} ${info.row.original.unit}`,
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor(value => value.cowStage.name, {
        header: "Cow Stage",
    }),
    columnHelper.accessor("comments", {
        header: "Comments",
    }),
];

const ProactiveTreatmentsTable = ({proactiveTreatments}) => {
    const navigate = useNavigate();

    return <DataTable title="Proactive Treatments" data={proactiveTreatments} columns={columns} useFilter
                      onRowClick={(row) => navigate(row.id.toString())}/>;
}

export default ProactiveTreatmentsTable;