import {Menu, Transition} from "@headlessui/react";
import {UserIcon} from "@heroicons/react/24/outline";
import {Fragment} from "react";
import {useAuthenticator} from "@aws-amplify/ui-react";

const MenuButton = () =>
    <Menu.Button className="rounded-md p-2 hover:bg-green-50 dark:hover:bg-gray-700 dark:text-white">
        <UserIcon className="block h-6 w-6"/>
    </Menu.Button>

const UsernameItem = ({username}) =>
    <Menu.Item>
        <span className="font-bold text-center block px-4 py-2 text-sm text-gray-700 dark:text-white">
            {username}
        </span>
    </Menu.Item>

const LinkButton = ({text, onClick}) =>
    <Menu.Item>
        {({active}) => (
            <button type="button" onClick={onClick} className={`block min-w-full text-left px-4 py-2 text-sm 
            text-gray-700 dark:text-white ${active ? 'bg-green-50 dark:bg-gray-600 dark:text-green-400' : ''}`}>
                {text}
            </button>
        )}
    </Menu.Item>

const UserDropDown = () => {
    const {user, signOut} = useAuthenticator((context) => [context.user]);
    const username = user.attributes.email;

    return <Menu as="div" className="relative ml-3">
        <MenuButton/>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className="absolute right-0 z-10 mt-2 min-w-48 origin-top-right rounded-md py-1 shadow-lg
            bg-white divide-y divide-gray-100
            dark:bg-gray-700 dark:divide-gray-500 dark:border-solid dark:border-gray-500 dark:border">
                <div className="py-1">
                    <UsernameItem username={username}/>
                </div>
                <div className="py-1">
                    <LinkButton text="Sign out" onClick={signOut}/>
                </div>
            </Menu.Items>
        </Transition>
    </Menu>
}

export default UserDropDown;