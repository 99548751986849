import {Link} from "react-router-dom";
import {Disclosure} from "@headlessui/react";

const MobileNavItem = ({item}) =>
    <Link
        as={Disclosure.Button} to={item.to}
        className={`${item.current ?
            "bg-green-100 dark:bg-gray-900 dark:text-white" :
            "text-gray-600 hover:bg-green-50 hover:text-gray-800 " +
            "dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white"
        } block px-3 py-2 rounded-md text-base font-medium`}
        aria-current={item.current ? "page" : undefined}
    >
        {item.name}
    </Link>

export default MobileNavItem;