import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormContainer from "../../../components/Layout/FormContainer";
import FormDescription from "../../../components/Layout/FormDescription";
import ReactiveTreatmentUpdateForm, {formDataToReactiveTreatment} from "./ReactiveTreatmentUpdateForm";
import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";
import Treatments from "../../../services/UkTreatmentApi/Treatments";

export async function action({request, params}) {
    const formData = await request.formData();
    const reactiveTreatment = formDataToReactiveTreatment(formData);
    reactiveTreatment.productId = reactiveTreatment.product.id;
    reactiveTreatment.reasonId = reactiveTreatment.reason?.id;

    try {
        await Treatments.update(params.treatmentId, reactiveTreatment);

        return redirect(`/farms/${params.farmUid}/reactive_treatments`);
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {products, reasons, getReactiveTreatment} = useRouteLoaderData("farmReactiveTreatments");
    const {treatmentId} = useParams();
    const actionError = useActionData();
    const {farm} = useRouteLoaderData("currentFarm");

    const reactiveTreatment = useMemo(() => getReactiveTreatment(treatmentId), [getReactiveTreatment, treatmentId]);

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Reactive Treatment" text="Edit the reactive treatment."/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <ReactiveTreatmentUpdateForm farm={farm} reactiveTreatment={reactiveTreatment} products={products}
                                         reasons={reasons} id="update-reactive-treatment-form"/>
        </div>
    </FormContainer>;
}

export default Update;