import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import CowStageForm, {formDataToCowStage} from "./CowStageForm";
import CowStages from "../../../services/UkTreatmentApi/CowStages";
import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";

export async function action({request, params}) {
    const formData = await request.formData();
    const cowStage = formDataToCowStage(formData);

    try {
        await CowStages.update(params.cowStageId, cowStage);

        return redirect("/manage/cow_stages");
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {getCowStage} = useRouteLoaderData("manageCowStages");
    const {cowStageId} = useParams();
    const actionError = useActionData();

    const cowStage = useMemo(() => getCowStage(cowStageId), [getCowStage, cowStageId]);

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission");
    }, [actionError, handleErrorAlert]);

    const text = `Edit the cow stage. 
Assign the correct category. 

Tick the retired box if you want to hide this.
Delete if you accidentally created this and nothing else references it. (Else you have to delete all that references it)`;

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Cow Stage" text={text}/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <CowStageForm cowStage={cowStage} id="edit-cow-stage-form"/>
        </div>
    </FormContainer>;
}

export default Update;