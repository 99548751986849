import ResponsiveNavbar from "../components/Navbar/ResponsiveNavbar";
import {Outlet, useMatches, useNavigation} from "react-router-dom";
import Container from "../components/Layout/Container";
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import Alerts from "../components/Alert/Alerts";
import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";

const logo =
    <>
        <img className="block dark:hidden h-8 w-auto" src={`${process.env.PUBLIC_URL}/ahv-logo-light.png`} alt="AHV"/>
        <img className="hidden dark:block h-8 w-auto" src={`${process.env.PUBLIC_URL}/ahv-logo-dark.png`} alt="AHV"/>
    </>;
const defaultNavItems = [
    {
        name: "Home",
        to: "/",
    },
];

const Breadcrumbs = () => {
    const matches = useMatches();
    const crumbs = matches.filter(match => Boolean(match.handle?.crumb)).map(match => match.handle.crumb(match));

    return <div className="bg-white dark:bg-gray-800 shadow-sm rounded-lg sm:h-12 my-4 sm:my-8
    border dark:border-gray-500 flex flex-wrap items-center pl-0 sm:pl-4 pr-4 py-3 sm:py-0">
        {crumbs.map((crumb, index) =>
            <span key={index} className="flex items-center py-2 pl-4">
                {crumb}

                {index !== crumbs.length - 1 ?
                    <span className="pl-4">
                        <ChevronRightIcon className="h-3 w-3 dark:fill-white"/>
                    </span> :
                    null
                }
            </span>
        )}
    </div>;
}

const Layout = () => {
    const [navItems, setNavItems] = useState(defaultNavItems);
    const navigation = useNavigation();

    useEffect(() => {
        Auth.currentSession().then(s => {
            if (s.getIdToken().payload["cognito:groups"]?.includes("admin"))
                setNavItems([...defaultNavItems, {
                    name: "Manage",
                    to: "/manage"
                }]);
        });
    }, []);

    return <div className="min-h-screen bg-gray-100 dark:bg-gray-700">
        <ResponsiveNavbar logo={logo} navItems={navItems}/>

        <Container>
            <Breadcrumbs/>
        </Container>

        <Container>
            <Alerts/>
        </Container>

        <div className={navigation.state === "loading"
            ? "opacity-25 transition-opacity delay-200 pointer-events-none"
            : ""}
        >
            <Outlet/>
        </div>
    </div>;
}

export default Layout;