import {Switch} from "@headlessui/react";

const BasicSwitch = (props) =>
    <Switch className={`${
        props.checked ? "bg-green-400 dark:bg-green-600" : "bg-gray-200 dark:bg-gray-600"
    } relative inline-flex h-6 w-11 items-center rounded-full mt-3 mb-2`} {...props}>
        <span className={`${
            props.checked ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}/>
    </Switch>

export default BasicSwitch;