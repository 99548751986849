import {useActionData, useRouteLoaderData} from "react-router-dom";
import Container from "../../../components/Layout/Container";
import Create from "./Create";
import ReactiveTreatmentsTable from "./ReactiveTreatmentsTable";
import {formDataToReactiveTreatments} from "./ReactiveTreatmentsCreateForm";
import ProductCategories from "../../../services/UkTreatmentApi/ProductCategories";
import {useContext, useEffect} from "react";
import AlertContext from "../../../contexts/AlertContext";
import Products from "../../../services/UkTreatmentApi/Products";
import {orderBy, sortBy} from "lodash";
import TreatmentReasons from "../../../services/UkTreatmentApi/TreatmentReasons";
import ReasonCategories from "../../../services/UkTreatmentApi/ReasonCategories";
import Treatments from "../../../services/UkTreatmentApi/Treatments";

export async function loader({params}) {
    let [reactiveTreatments, antibiotics, products, categories, reasons, reasonCategories] = await Promise.all([
        Treatments.ofFarmWithProgram(params.farmUid, "Health Challenges").then(({data}) => data),
        ProductCategories.antibioticsCategory().then(({data}) => data),
        Products.index().then(({data}) => sortBy(data, ["name"])),
        ProductCategories.index().then(({data}) => data),
        TreatmentReasons.index().then(({data}) => sortBy(data, ["name"])),
        ReasonCategories.index().then(({data}) => data),
    ]);

    products = products
        .filter(p => p.categoryId !== antibiotics.id)
        .map(p => ({...p, category: categories.find(c => c.id === p.categoryId)}));
    reasons.forEach(r => r.category = reasonCategories.find(c => c.id === r.categoryId));
    reactiveTreatments = orderBy(
        reactiveTreatments.map(t => ({
            ...t,
            product: products.find(p => p.id === t.productId),
            reason: reasons.find(r => r.id === t.reasonId),
        })),
        ["treatmentDate", "cowNumber", "product.name"],
        ["desc", "asc", "desc"]
    );

    const getReactiveTreatment = (id) => {
        const reactiveTreatment = reactiveTreatments.find(t => t.id === parseInt(id));
        if (!reactiveTreatment) throw new Error(`Couldn't find reactive treatment with id \`${id}\``);

        return reactiveTreatment;
    }

    products = products.filter(p => !p.retired);
    reasons = reasons.filter(r => !r.retired);

    return {reactiveTreatments, products, reasons, getReactiveTreatment};
}

export async function action({request}) {
    const formData = await request.formData();
    const reactiveTreatments = formDataToReactiveTreatments(formData);
    const treatments = reactiveTreatments.cowNumbers.map(cowNumber =>
        reactiveTreatments.treatments.map(treatment => ({
            program: reactiveTreatments.program,
            farmUid: reactiveTreatments.farmUid,
            cowNumber,
            treatmentDate: reactiveTreatments.treatmentDate,
            product: treatment.product,
            productId: treatment.product.id,
            dosage: treatment.dosage,
            unit: treatment.unit,
            reason: reactiveTreatments.reason,
            reasonId: reactiveTreatments.reason?.id,
            comments: reactiveTreatments.comments,
        }))
    ).flat();

    try {
        return {result: await Treatments.create({treatments}).then(({data}) => data)};
    } catch (error) {
        return {error};
    }
}

const ReactiveTreatments = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {reactiveTreatments, products, reasons} = useRouteLoaderData("farmReactiveTreatments");
    const {farm} = useRouteLoaderData("currentFarm");
    const actionError = useActionData();

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    return <>
        <Create farm={farm} products={products} reasons={reasons}/>

        <div className="mt-4 sm:mt-8">
            <Container>
                <ReactiveTreatmentsTable reactiveTreatments={reactiveTreatments}/>
            </Container>
        </div>
    </>;
}

export default ReactiveTreatments;