import Treatments from "../../../services/UkTreatmentApi/Treatments";
import ProductCategories from "../../../services/UkTreatmentApi/ProductCategories";
import Products from "../../../services/UkTreatmentApi/Products";
import CowStages from "../../../services/UkTreatmentApi/CowStages";
import ProductPlans from "../../../services/UkTreatmentApi/ProductPlans";
import {orderBy, sortBy} from "lodash";
import AlertContext from "../../../contexts/AlertContext";
import {useActionData, useRouteLoaderData} from "react-router-dom";
import {useContext, useEffect} from "react";
import ProactiveTreatmentsTable from "./ProactiveTreatmentsTable";
import Container from "../../../components/Layout/Container";
import Create from "./Create";
import {formDataToProactiveTreatments} from "./ProactiveTreatmentsCreateForm";

export async function loader({params}) {
    let [proactiveTreatments, antibiotics, products, categories, stages, plans] = await Promise.all([
        Treatments.ofFarmWithProgram(params.farmUid, "Herd Health Plan").then(({data}) => data),
        ProductCategories.antibioticsCategory().then(({data}) => data),
        Products.index().then(({data}) => sortBy(data, ["name"])),
        ProductCategories.index().then(({data}) => data),
        CowStages.index().then(({data}) => sortBy(data, ["name"])),
        ProductPlans.index().then(({data}) => data),
    ]);

    products = products
        .filter(p => p.categoryId !== antibiotics.id)
        .map(p => ({...p, category: categories.find(c => c.id === p.categoryId)}));
    proactiveTreatments = orderBy(
        proactiveTreatments.map(t => ({
            ...t,
            product: products.find(p => p.id === t.productId),
            cowStage: stages.find(s => s.id === t.cowStageId),
        })),
        ["treatmentDate", "cowNumber", "product.name"],
        ["desc", "asc", "desc"]
    );
    plans = plans.reduce((result, plan) => {
        const cowStage = stages.find(s => s.id === plan.cowStageId).name;
        if (!result[cowStage]) result[cowStage] = [];
        result[cowStage].push({
            product: products.find(p => p.id === plan.productId),
            dosage: plan.dosage,
            unit: plan.unit,
        });

        return result;
    }, {});
    Object.keys(plans).forEach(plan => plans[plan] = sortBy(plans[plan], ["product.name"]));

    const getProactiveTreatment = (id) => {
        const proactiveTreatment = proactiveTreatments.find(t => t.id === parseInt(id));
        if (!proactiveTreatment) throw new Error(`Couldn't find proactive treatment with id \`${id}\``);

        return proactiveTreatment;
    }

    products = products.filter(p => !p.retired);
    stages = stages.filter(s => !s.retired);

    return {proactiveTreatments, products, stages, plans, getProactiveTreatment};
}

export async function action({request}) {
    const formData = await request.formData();
    const proactiveTreatments = formDataToProactiveTreatments(formData);
    const treatments = proactiveTreatments.cowNumbers.map(cowNumber =>
        proactiveTreatments.treatments.map(treatment => ({
            program: proactiveTreatments.program,
            farmUid: proactiveTreatments.farmUid,
            cowNumber,
            treatmentDate: proactiveTreatments.treatmentDate,
            product: treatment.product,
            productId: treatment.product.id,
            dosage: treatment.dosage,
            unit: treatment.unit,
            cowStage: proactiveTreatments.cowStage,
            cowStageId: proactiveTreatments.cowStage.id,
            comments: proactiveTreatments.comments,
        }))
    ).flat();

    try {
        return {result: await Treatments.create({treatments}).then(({data}) => data)};
    } catch (error) {
        return {error};
    }
}

const ProactiveTreatments = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {proactiveTreatments, products, stages, plans} = useRouteLoaderData("farmProactiveTreatments");
    const {farm} = useRouteLoaderData("currentFarm");
    const actionError = useActionData();

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    return <>
        <Create farm={farm} products={products} stages={stages} plans={plans}/>

        <div className="mt-4 sm:mt-8">
            <Container>
                <ProactiveTreatmentsTable proactiveTreatments={proactiveTreatments}/>
            </Container>
        </div>
    </>;
}

export default ProactiveTreatments;