import {Disclosure} from "@headlessui/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import Switcher from "../DarkModeSwitcher/Switcher";
import DesktopNavItem from "./DesktopNavItem";
import UserDropDown from "./UserDropDown";
import MobileNavItem from "./MobileNavItem";

const DisclosureButton = ({open}) =>
    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <Disclosure.Button
            className="inline-flex items-center justify-center rounded-md p-2
            hover:bg-green-50 dark:hover:bg-gray-700 dark:text-white"
            aria-controls="mobile-menu" aria-expanded="false"
        >
            {open ?
                <XMarkIcon className="block h-6 w-6" aria-hidden="true"/> :
                <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
            }
        </Disclosure.Button>
    </div>

const ResponsiveNavbar = ({logo, navItems}) => {
    return <Disclosure as="nav" className="bg-white dark:bg-gray-800 shadow">
        {({open}) => (
            <>
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div className="relative flex h-16 items-center justify-between">
                        <DisclosureButton open={open}/>

                        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                            {logo}

                            <div className="hidden sm:ml-6 sm:block">
                                <div className="flex space-x-4">
                                    {navItems.map(item => <DesktopNavItem key={item.name} item={item}/>)}
                                </div>
                            </div>
                        </div>

                        <div className="absolute inset-y-0 right-0 flex items-center pr-2
                        sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <Switcher/>

                            <UserDropDown/>
                        </div>
                    </div>
                </div>

                <Disclosure.Panel className="sm:hidden border-t dark:border-slate-900">
                    <div className="space-y-1 px-2 px-2 pt-2 pb-3">
                        {navItems.map(item => <MobileNavItem key={item.name} item={item}/>)}
                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

export default ResponsiveNavbar;