import FormContainer from "../../../components/Layout/FormContainer";
import FormDescription from "../../../components/Layout/FormDescription";
import ProactiveTreatmentsCreateForm from "./ProactiveTreatmentsCreateForm";

const Create = ({farm, products, stages, plans}) =>
    <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Proactive Treatments" text="Register the proactive treatments in the app."/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <ProactiveTreatmentsCreateForm farm={farm} products={products} stages={stages} plans={plans}
                                           id="create-proactive-treatments-form"/>
        </div>
    </FormContainer>

export default Create;