import {redirect, useActionData, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import CullingReasonForm, {formDataToCullingReason} from "./CullingReasonForm";
import FormContainer from "../../../components/Layout/FormContainer";
import CullingReasons from "../../../services/UkTreatmentApi/CullingReasons";
import {useContext, useEffect} from "react";
import AlertContext from "../../../contexts/AlertContext";

export async function action({request, params}) {
    const formData = await request.formData();
    const cullingReason = formDataToCullingReason(formData);

    try {
        await CullingReasons.create(cullingReason);

        return redirect(`/farms/${params.farmUid}/culling_reasons`);
    } catch (error) {
        return error;
    }
}

const Create = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {farm} = useRouteLoaderData("currentFarm");
    const actionError = useActionData();

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Culling Reason" text="Register the culling reason in the app."/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <CullingReasonForm farm={farm} id="create-culling-reason-form"/>
        </div>
    </FormContainer>;
}

export default Create;