import {destroy, get, post, put} from "./base";

const ProductCategories = {
    index: () => get("/product_categories"),
    create: (body) => post("/product_categories", body),
    single: (id) => get(`/product_categories/${id}`),
    update: (id, body) => put(`/product_categories/${id}`, body),
    delete: (id) => destroy(`/product_categories/${id}`),
    antibioticsCategory: () => get("/antibiotics_category"),
};

export default ProductCategories;