import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import ReactiveTreatmentsCreateForm from "./ReactiveTreatmentsCreateForm";

const Create = ({farm, products, reasons}) =>
    <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Reactive Treatments" text="Register the reactive treatments in the app."/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <ReactiveTreatmentsCreateForm farm={farm} products={products} reasons={reasons}
                                          id="create-reactive-treatments-form"/>
        </div>
    </FormContainer>

export default Create;