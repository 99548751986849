import {destroy, get, post, put} from "./base";

const CullingReasons = {
    index: () => get("/culling_reasons"),
    create: (body) => post("/culling_reasons", body),
    single: (id) => get(`/culling_reasons/${id}`),
    update: (id, body) => put(`/culling_reasons/${id}`, body),
    delete: (id) => destroy(`/culling_reasons/${id}`),
    ofFarm: (uid) => get(`/culling_reasons_of_farm/${uid}`),
};

export default CullingReasons;