import {useActionData, useRouteLoaderData} from "react-router-dom";
import Container from "../../../components/Layout/Container";
import AntibioticUsagesTable from "./AntibioticUsagesTable";
import Create from "./Create";
import {formDataToAntibioticUsage} from "./AntibioticUsageForm";
import ProductCategories from "../../../services/UkTreatmentApi/ProductCategories";
import Products from "../../../services/UkTreatmentApi/Products";
import {orderBy, sortBy} from "lodash";
import TreatmentReasons from "../../../services/UkTreatmentApi/TreatmentReasons";
import ReasonCategories from "../../../services/UkTreatmentApi/ReasonCategories";
import Treatments from "../../../services/UkTreatmentApi/Treatments";
import AlertContext from "../../../contexts/AlertContext";
import {useContext, useEffect} from "react";

export async function loader({params}) {
    let [antibioticUsages, antibiotics, products, reasons, reasonCategories] = await Promise.all([
        Treatments.ofFarmWithProgram(params.farmUid, "Antibiotic Usage").then(({data}) => data),
        ProductCategories.antibioticsCategory().then(({data}) => data),
        Products.index().then(({data}) => sortBy(data, ["name"])),
        TreatmentReasons.index().then(({data}) => sortBy(data, ["name"])),
        ReasonCategories.index().then(({data}) => data),
    ]);

    products = products
        .filter(p => p.categoryId === antibiotics.id)
        .map(p => ({...p, category: antibiotics}));
    reasons.forEach(r => r.category = reasonCategories.find(c => c.id === r.categoryId));
    antibioticUsages = orderBy(
        antibioticUsages.map(u => ({
            ...u,
            product: products.find(p => p.id === u.productId),
            reason: reasons.find(r => r.id === u.reasonId),
        })),
        ["treatmentDate", "cowNumber", "product.name"],
        ["desc", "asc", "desc"]
    );

    const getAntibioticUsage = (id) => {
        const antibioticUsage = antibioticUsages.find(u => u.id === parseInt(id));
        if (!antibioticUsage) throw new Error(`Couldn't find antibiotic usage with id \`${id}\``);

        return antibioticUsage;
    }

    products = products.filter(p => !p.retired);
    reasons = reasons.filter(r => !r.retired);

    return {antibioticUsages, products, reasons, getAntibioticUsage};
}

export async function action({request}) {
    const formData = await request.formData();
    const antibioticUsage = formDataToAntibioticUsage(formData);
    antibioticUsage.productId = antibioticUsage.product.id;
    antibioticUsage.reasonId = antibioticUsage.reason?.id;

    try {
        return {result: await Treatments.create({"treatments": [antibioticUsage]}).then(({data}) => data)};
    } catch (error) {
        return {error};
    }
}

const AntibioticUsages = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {antibioticUsages, products, reasons} = useRouteLoaderData("farmAntibioticUsages");
    const {farm} = useRouteLoaderData("currentFarm");
    const actionError = useActionData();

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    return <>
        <Create farm={farm} products={products} reasons={reasons}/>

        <div className="mt-4 sm:mt-8">
            <Container>
                <AntibioticUsagesTable antibioticUsages={antibioticUsages}/>
            </Container>
        </div>
    </>;
}

export default AntibioticUsages;