import {destroy, get, post, put} from "./base";

const FertilityPerformances = {
    index: () => get("/fertility_performances"),
    create: (body) => post("/fertility_performances", body),
    single: (id) => get(`/fertility_performances/${id}`),
    update: (id, body) => put(`/fertility_performances/${id}`, body),
    delete: (id) => destroy(`/fertility_performances/${id}`),
    ofFarm: (uid) => get(`/fertility_performances_of_farm/${uid}`),
};

export default FertilityPerformances;