import {createColumnHelper} from "@tanstack/react-table";
import {useNavigate, useRouteLoaderData} from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import Container from "../../../components/Layout/Container";
import {default as ApiProducts} from "../../../services/UkTreatmentApi/Products";
import {sortBy} from "lodash";
import ProductCategories from "../../../services/UkTreatmentApi/ProductCategories";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor("id", {
        header: "ID",
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("name", {
        header: "Product Name",
    }),
    columnHelper.accessor("defaultUnit", {
        header: "Default Unit",
    }),
    columnHelper.accessor("category.name", {
        header: "Category",
    }),
    columnHelper.accessor(value => value.retired ? "X" : "", {
        header: "Retired",
    }),
];

export async function loader() {
    const [products, categories] = await Promise.all([
        ApiProducts.index().then(({data}) => sortBy(data, ["name"])),
        ProductCategories.index().then(({data}) => sortBy(data, ["name"])),
    ]);

    products.forEach(p => p.category = categories.find(c => c.id === p.categoryId));

    const getProduct = (id) => {
        const product = products.find(p => p.id === parseInt(id));
        if (!product) throw new Error(`Couldn't find product with id \`${id}\``);

        return product;
    }

    return {products, categories, getProduct};
}

const Products = () => {
    const {products} = useRouteLoaderData("manageProducts");
    const navigate = useNavigate();

    return <Container>
        <DataTable title="Products" data={products} columns={columns} useFilter={true}
                   onCreateClick={() => navigate("create")} onRowClick={(row) => navigate(row.id.toString())}/>
    </Container>;
}

export default Products;