import * as yup from "yup";
import {useLocation, useNavigation, useSubmit} from "react-router-dom";
import {Formik} from "formik";
import moment from "moment-timezone";
import FormikTextInput from "../../../components/Input/FormikTextInput";
import FormikDatePicker from "../../../components/Input/FormikDatePicker";
import BasicPrimaryButton from "../../../components/Button/BasicPrimaryButton";
import BasicErrorButton from "../../../components/Button/BasicErrorButton";
import FormikRadioWithOtherText from "../../../components/Input/FormikRadioWithOtherText";
import BasicLabel from "../../../components/Input/BasicLabel";
import FormikError from "../../../components/Input/FormikError";

const reasons = [
    "Fertility",
    "Udder Health",
    "Poor Yield",
    "Legs / Feet",
    "Advanced Age",
    "Physical Injury",
];
const cullingReasonSchema = yup.object({
    id: yup.number().positive().integer(),
    farmUid: yup.string().required(),
    cowNumber: yup.string().trim()
        .required("Cow Number is required")
        .max(255, "Must be 255 characters or less"),
    cullingDate: yup.number().positive().integer()
        .required("Culling Date is required"),
    reason: yup.string().trim()
        .required("Reason is required")
        .max(255, "Must be 255 characters or less"),
    otherReason: yup.string().trim()
        .when("reason", {
            is: "Other",
            then: (schema) => schema
                .required("Please fill in the other reason")
                .max(255, "Must be 255 characters or less"),
        })
        .default(""),
});

export function formDataToCullingReason(formData) {
    const cullingReason = Object.fromEntries(formData);
    if (cullingReason.reason !== "Other") delete cullingReason["otherReason"];

    return cullingReasonSchema.cast(cullingReason);
}

const CullingReasonForm = ({farm, cullingReason, ...props}) => {
    const navigation = useNavigation();
    const submit = useSubmit();
    const location = useLocation();

    const handleDelete = event => {
        if (!window.confirm("Please confirm you want to delete")) event.preventDefault();
        else submit(null, {action: `${location.pathname}/delete`, method: "post"});
    }

    return <Formik
        initialValues={{
            farmUid: farm.uid,
            cowNumber: cullingReason?.cowNumber || "",
            cullingDate: cullingReason?.cullingDate || moment().valueOf(),
            reason: cullingReason?.reason || "",
            otherReason: cullingReason?.otherReason || "",
        }}
        validationSchema={cullingReasonSchema}
        onSubmit={async values => submit(values, {method: "post"})}
    >
        {formik => <form className="px-4 sm:px-6" method="post" onSubmit={formik.handleSubmit} {...props}>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-6">
                    <FormikDatePicker label="Culling Date" name="cullingDate"/>
                </div>

                <div className="col-span-12 sm:col-span-6">
                    <FormikTextInput label="Cow Number" name="cowNumber"/>
                </div>

                <div className="col-span-12">
                    <BasicLabel htmlFor="reason">Reason</BasicLabel>
                    <FormikRadioWithOtherText name="reason" options={reasons} otherName="otherReason"/>
                    <FormikError name="reason"/>
                    <FormikError name="otherReason"/>
                </div>
            </div>

            <div className="mt-4 py-3 flex justify-between">
                <BasicPrimaryButton disabled={navigation.state === "submitting"} type="submit">
                    Submit
                </BasicPrimaryButton>

                {cullingReason &&
                    <BasicErrorButton onClick={handleDelete} disabled={navigation.state === "submitting"}>
                        Delete
                    </BasicErrorButton>}
            </div>
        </form>}
    </Formik>;
}

export default CullingReasonForm;