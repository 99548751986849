import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import ProductCategoryForm, {formDataToProductCategory} from "./ProductCategoryForm";
import ProductCategories from "../../../services/UkTreatmentApi/ProductCategories";
import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";

export async function action({request, params}) {
    const formData = await request.formData();
    const productCategory = formDataToProductCategory(formData);

    try {
        await ProductCategories.update(params.productCategoryId, productCategory);

        return redirect("/manage/product_categories");
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {getProductCategory} = useRouteLoaderData("manageProductCategories");
    const {productCategoryId} = useParams();
    const actionError = useActionData();

    const productCategory = useMemo(() => getProductCategory(productCategoryId), [getProductCategory, productCategoryId]);

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission");
    }, [actionError, handleErrorAlert]);

    const text = `Edit the product category. 
    
Delete if you accidentally created this and nothing else references it. (Else you have to delete all that references it)`;

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Product Category" text={text}/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <ProductCategoryForm productCategory={productCategory} id="edit-product-category-form"/>
        </div>
    </FormContainer>;
}

export default Update;