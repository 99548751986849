import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import ReasonCategoryForm, {formDataToReasonCategory} from "./ReasonCategoryForm";
import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";
import ReasonCategories from "../../../services/UkTreatmentApi/ReasonCategories";

export async function action({request, params}) {
    const formData = await request.formData();
    const reasonCategory = formDataToReasonCategory(formData);

    try {
        await ReasonCategories.update(params.reasonCategoryId, reasonCategory);

        return redirect("/manage/reason_categories");
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {getReasonCategory} = useRouteLoaderData("manageReasonCategories");
    const {reasonCategoryId} = useParams();
    const actionError = useActionData();

    const reasonCategory = useMemo(() => getReasonCategory(reasonCategoryId), [getReasonCategory, reasonCategoryId]);

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission");
    }, [actionError, handleErrorAlert]);

    const text = `Edit the reason category. 
    
Delete if you accidentally created this and nothing else references it. (Else you have to delete all that references it)`;

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Reason Category" text={text}/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <ReasonCategoryForm reasonCategory={reasonCategory} id="edit-reason-category-form"/>
        </div>
    </FormContainer>;
}

export default Update;