import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";
import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormContainer from "../../../components/Layout/FormContainer";
import FormDescription from "../../../components/Layout/FormDescription";
import ProactiveTreatmentUpdateForm, {formDataToProactiveTreatment} from "./ProactiveTreatmentUpdateForm";
import Treatments from "../../../services/UkTreatmentApi/Treatments";

export async function action({request, params}) {
    const formData = await request.formData();
    const proactiveTreatment = formDataToProactiveTreatment(formData);
    proactiveTreatment.productId = proactiveTreatment.product.id;

    try {
        await Treatments.update(params.treatmentId, proactiveTreatment);

        return redirect(`/farms/${params.farmUid}/proactive_treatments`);
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {products, getProactiveTreatment} = useRouteLoaderData("farmProactiveTreatments");
    const {treatmentId} = useParams();
    const actionError = useActionData();
    const {farm} = useRouteLoaderData("currentFarm");

    const proactiveTreatment = useMemo(() => getProactiveTreatment(treatmentId), [getProactiveTreatment, treatmentId]);

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Proactive Treatment" text="Edit the proactive treatment."/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <ProactiveTreatmentUpdateForm farm={farm} proactiveTreatment={proactiveTreatment} products={products}
                                          id="update-proactive-treatment-form"/>
        </div>
    </FormContainer>;
}

export default Update;