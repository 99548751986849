import {useField, useFormikContext} from "formik";
import BasicLabel from "./BasicLabel";
import BasicSelect from "./BasicSelect";
import FormikError from "./FormikError";
import {XMarkIcon} from "@heroicons/react/24/outline";

const FormikSelectWithCross = ({label, onCrossClick, ...props}) => {
    const [field] = useField(props);
    const {setFieldValue} = useFormikContext();

    return <>
        <div className="flex justify-between items-center">
            <BasicLabel htmlFor={props.id || props.name}>{label}</BasicLabel>
            <XMarkIcon
                onClick={onCrossClick}
                className="h-4 w-4 cursor-pointer text-red-400 dark:text-red-600
                hover:text-red-600 dark:hover:text-red-400"
            />
        </div>
        <BasicSelect value={field.value} onChange={value => setFieldValue(props.name, value)} {...props}/>
        <FormikError name={props.name}/>
    </>;
}

export default FormikSelectWithCross;