import Container from "./Container";
import Jumbotron from "./Jumbotron";

const FormContainer = (props) =>
    <Container>
        <Jumbotron>
            <div className="px-0 sm:px-6 lg:px-8">
                <div className="py-8 md:grid md:grid-cols-4 md:gap-6" {...props}/>
            </div>
        </Jumbotron>
    </Container>

export default FormContainer;