import {Link, useNavigate, useRouteError} from "react-router-dom";

const ErrorPage = () => {
    const error = useRouteError();
    const navigate = useNavigate();

    return <div className="max-h-screen grid place-items-center py-24 px-6 sm:py-32 lg:px-8 dark:bg-gray-700">
        <div className="text-center">
            <p className="text-base font-semibold text-green-600">
                Oops!
            </p>

            <h1 className="mt-4 text-3xl sm:text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
                {error.statusText || error.message}
            </h1>

            <p className="mt-6 text-base leading-7 text-gray-600 dark:text-white">
                {error.response?.data.message || error.response?.data || "Sorry, an unexpected error has occurred."}
            </p>

            <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm
                hover:bg-green-500" onClick={() => navigate(-1)}>
                    <span aria-hidden="true">&larr;</span> Go back
                </Link>
            </div>
        </div>
    </div>
}

export default ErrorPage;