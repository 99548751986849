import {destroy, get, post, put} from "./base";

const CowStages = {
    index: () => get("/cow_stages"),
    create: (body) => post("/cow_stages", body),
    single: (id) => get(`/cow_stages/${id}`),
    update: (id, body) => put(`/cow_stages/${id}`, body),
    delete: (id) => destroy(`/cow_stages/${id}`),
};

export default CowStages;