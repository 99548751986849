const Description = ({title, text}) =>
    <div className="mx-auto max-w-2xl pl-1 sm:pl-0 sm:text-center">
        <h2 className="text-3xl font-medium tracking-tight text-gray-900 dark:text-white">
            {title}
        </h2>

        {text &&
            <p className="mt-2 text-lg text-gray-600 dark:text-gray-400">
                {text}
            </p>
        }
    </div>

export default Description;