import axios from "axios";
import {Auth} from "aws-amplify";

const apiClient = axios.create({baseURL: process.env.REACT_APP_UK_API_URI, timeout: 30000});
apiClient.interceptors.request.use(
    async (config) => {
        config.headers["Authorization"] = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

        return config;
    },
    (error) => Promise.reject(error)
);
export const {get, post, put, delete: destroy} = apiClient;