import {destroy, get, post, put} from "./base";

const Products = {
    index: () => get("/products"),
    create: (body) => post("/products", body),
    single: (id) => get(`/products/${id}`),
    update: (id, body) => put(`/products/${id}`, body),
    delete: (id) => destroy(`/products/${id}`),
};

export default Products;