import {createColumnHelper} from "@tanstack/react-table";
import {useNavigate, useRouteLoaderData} from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import Container from "../../../components/Layout/Container";
import ReasonCategories from "../../../services/UkTreatmentApi/ReasonCategories";
import {sortBy} from "lodash";
import {default as ApiTreatmentReasons} from "../../../services/UkTreatmentApi/TreatmentReasons";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor("id", {
        header: "ID",
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("name", {
        header: "Reason",
    }),
    columnHelper.accessor("category.name", {
        header: "Category",
    }),
    columnHelper.accessor(value => value.retired ? "X" : "", {
        header: "Retired",
    }),
];

export async function loader() {
    const [treatmentReasons, categories] = await Promise.all([
        ApiTreatmentReasons.index().then(({data}) => sortBy(data, ["name"])),
        ReasonCategories.index().then(({data}) => sortBy(data, ["name"])),
    ]);

    treatmentReasons.forEach(r => r.category = categories.find(c => c.id === r.categoryId));

    const getTreatmentReason = (id) => {
        const treatmentReason = treatmentReasons.find(r => r.id === parseInt(id));
        if (!treatmentReason) throw new Error(`Couldn't find treatment reason with id \`${id}\``);

        return treatmentReason;
    }

    return {treatmentReasons, categories, getTreatmentReason};
}

const TreatmentReasons = () => {
    const {treatmentReasons} = useRouteLoaderData("manageTreatmentReasons");
    const navigate = useNavigate();

    return <Container>
        <DataTable title="Treatment Reasons" data={treatmentReasons} columns={columns} useFilter={true}
                   onCreateClick={() => navigate("create")} onRowClick={(row) => navigate(row.id.toString())}/>
    </Container>;
}

export default TreatmentReasons;