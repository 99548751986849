import {useFormikContext} from "formik";
import FormikTextInput from "./FormikTextInput";
import {useEffect} from "react";

const ProductUnitInput = ({productValue, ...props}) => {
    const {setFieldValue} = useFormikContext();

    useEffect(() => {
        if (productValue) setFieldValue(props.name, productValue.defaultUnit);
    }, [productValue, setFieldValue, props.name]);

    return <FormikTextInput {...props}/>
}

export default ProductUnitInput;