import AlertContext from "../../contexts/AlertContext";
import {XMarkIcon} from "@heroicons/react/24/outline";

function getColor(status) {
    return status === "error" ? "red"
        : status === "success" ? "green"
            : status === "info" ? "blue"
                : "yellow";
}

const Alerts = () =>
    <AlertContext.Consumer>
        {({alerts, deleteAlert}) => alerts.map((alert, i) => {
            const color = getColor(alert.status);

            return <div key={alert.id} className={`mt-2 sm:mt-3 rounded-lg shadow-sm px-4 sm:px-8 py-3 border
            ${i === alerts.length - 1 ? " mb-4 sm:mb-4" : ""} border-${color}-200 dark:border-${color}-800 
            bg-${color}-200 text-${color}-600`}>
                <div className="flex justify-between">
                    <h5 className={`font-semibold text-${color}-700 dark:text-${color}-100`}>{alert.title}</h5>

                    <XMarkIcon
                        onClick={() => deleteAlert(alert.id)}
                        className="h-5 w-5 cursor-pointer text-gray-500 text-red-400 dark:text-red-600
                        hover:text-red-600 dark:hover:text-red-400"
                    />
                </div>

                <p className="whitespace-pre-wrap">{alert.description}</p>
            </div>;
        })}
    </AlertContext.Consumer>

export default Alerts;