import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider,} from "react-router-dom";
import Layout from "./routes/Layout";
import Farm, {loader as farmLoader} from "./routes/Farms/Farm";
import ErrorPage from "./routes/ErrorPage";
import Root from "./routes/Root";
import {HomeIcon} from "@heroicons/react/24/solid";
import Crumb from "./components/Breadcrumb/Crumb";
import {default as ManageFarms, loader as farmsLoader} from "./routes/Manage/Farms/Farms";
import {default as ManageRoot} from "./routes/Manage/Root";
import {action as createFarmAction, default as CreateFarm,} from "./routes/Manage/Farms/Create";
import {action as updateFarmAction, default as UpdateFarm,} from "./routes/Manage/Farms/Update";
import {action as deleteFarmAction} from "./routes/Manage/Farms/Delete";
import {
    default as ManageProductCategories,
    loader as productCategoriesLoader,
} from "./routes/Manage/ProductCategories/ProductCategories";
import {
    action as createProductCategoryAction,
    default as CreateProductCategory,
} from "./routes/Manage/ProductCategories/Create";
import {
    action as updateProductCategoryAction,
    default as UpdateProductCategory,
} from "./routes/Manage/ProductCategories/Update";
import {action as deleteProductCategoryAction} from "./routes/Manage/ProductCategories/Delete";
import {default as ManageProducts, loader as productsLoader} from "./routes/Manage/Products/Products";
import {action as createProductAction, default as CreateProduct,} from "./routes/Manage/Products/Create";
import {action as updateProductAction, default as UpdateProduct,} from "./routes/Manage/Products/Update";
import {action as deleteProductAction} from "./routes/Manage/Products/Delete";
import {
    default as ManageReasonCategories,
    loader as reasonCategoriesLoader,
} from "./routes/Manage/ReasonCategories/ReasonCategories";
import {
    action as createReasonCategoryAction,
    default as CreateReasonCategory,
} from "./routes/Manage/ReasonCategories/Create";
import {
    action as updateReasonCategoryAction,
    default as UpdateReasonCategory,
} from "./routes/Manage/ReasonCategories/Update";
import {action as deleteReasonCategoryAction} from "./routes/Manage/ReasonCategories/Delete";
import {
    default as ManageTreatmentReasons,
    loader as treatmentReasonsLoader,
} from "./routes/Manage/TreatmentReasons/TreatmentReasons";
import {
    action as createTreatmentReasonAction,
    default as CreateTreatmentReason,
} from "./routes/Manage/TreatmentReasons/Create";
import {
    action as updateTreatmentReasonAction,
    default as UpdateTreatmentReason,
} from "./routes/Manage/TreatmentReasons/Update";
import {action as deleteTreatmentReasonAction} from "./routes/Manage/TreatmentReasons/Delete";
import {
    default as FarmFertilityPerformances,
    loader as farmFertilityPerformancesLoader,
} from "./routes/Farms/FertilityPerformances/FertilityPerformances";
import {
    action as createFertilityPerformanceAction,
    default as CreateFertilityPerformance,
} from "./routes/Farms/FertilityPerformances/Create";
import {
    action as updateFertilityPerformanceAction,
    default as UpdateFertilityPerformance,
} from "./routes/Farms/FertilityPerformances/Update";
import {action as deleteFertilityPerformanceAction} from "./routes/Farms/FertilityPerformances/Delete";
import {
    default as FarmCullingReasons,
    loader as farmCullingReasonsLoader,
} from "./routes/Farms/CullingReasons/CullingReasons";
import {
    action as createCullingReasonAction,
    default as CreateCullingReason,
} from "./routes/Farms/CullingReasons/Create";
import {
    action as updateCullingReasonAction,
    default as UpdateCullingReason,
} from "./routes/Farms/CullingReasons/Update";
import {action as deleteCullingReasonAction} from "./routes/Farms/CullingReasons/Delete";
import {default as FarmTreatments, loader as farmTreatmentsLoader,} from "./routes/Farms/Treatments/Treatments";
import {
    action as createAntibioticUsageAction,
    default as FarmAntibioticUsages,
    loader as farmAntibioticUsagesLoader,
} from "./routes/Farms/AntibioticUsages/AntibioticUsages";
import {
    action as updateAntibioticUsageAction,
    default as UpdateAntibioticUsage,
} from "./routes/Farms/AntibioticUsages/Update";
import {action as deleteAntibioticUsageAction} from "./routes/Farms/AntibioticUsages/Delete";
import {
    action as creatReactiveTreatmentsAction,
    default as FarmReactiveTreatments,
    loader as farmReactiveTreatmentsLoader,
} from "./routes/Farms/ReactiveTreatments/ReactiveTreatments";
import {
    action as updateReactiveTreatmentAction,
    default as UpdateReactiveTreatment,
} from "./routes/Farms/ReactiveTreatments/Update";
import {action as deleteReactiveTreatmentAction} from "./routes/Farms/ReactiveTreatments/Delete";
import {default as ManageCowStages, loader as cowStagesLoader,} from "./routes/Manage/CowStages/CowStages";
import {action as createCowStageAction, default as CreateCowStage,} from "./routes/Manage/CowStages/Create";
import {action as updateCowStageAction, default as UpdateCowStage,} from "./routes/Manage/CowStages/Update";
import {action as deleteCowStageAction} from "./routes/Manage/CowStages/Delete";
import {default as ManageProductPlans, loader as productPlansLoader,} from "./routes/Manage/ProductPlans/ProductPlans";
import {action as createProductPlanAction, default as CreateProductPlan,} from "./routes/Manage/ProductPlans/Create";
import {action as updateProductPlanAction, default as UpdateProductPlan,} from "./routes/Manage/ProductPlans/Update";
import {action as deleteProductPlanAction} from "./routes/Manage/ProductPlans/Delete";
import {
    action as createProactiveTreatmentsAction,
    default as FarmProactiveTreatments,
    loader as farmProactiveTreatmentsLoader,
} from "./routes/Farms/ProactiveTreatments/ProactiveTreatments";
import {
    action as updateProactiveTreatmentAction,
    default as UpdateProactiveTreatment,
} from "./routes/Farms/ProactiveTreatments/Update";
import {action as deleteProactiveTreatmentAction} from "./routes/Farms/ProactiveTreatments/Delete";
import {useMemo} from "react";


const App = () => {
    const router = useMemo(() => createBrowserRouter(createRoutesFromElements(
        <Route
            path="/"
            loader={farmsLoader}
            id="root"
            element={<Layout/>}
            handle={{crumb: ({pathname}) => <Crumb to={pathname}><HomeIcon className="h-5 w-5"/></Crumb>}}
            errorElement={<ErrorPage/>}
        >
            <Route errorElement={<ErrorPage/>}>
                <Route index element={<Root/>}/>

                <Route path="manage" handle={{crumb: ({pathname}) => <Crumb to={pathname}>Manage</Crumb>}}>
                    <Route index element={<ManageRoot/>}/>

                    <Route path="farms" handle={{crumb: ({pathname}) => <Crumb to={pathname}>Farms</Crumb>}}>
                        <Route index element={<ManageFarms/>}/>

                        <Route path="create" element={<CreateFarm/>} action={createFarmAction}/>

                        <Route path=":farmUid" element={<UpdateFarm/>} action={updateFarmAction}/>

                        <Route path=":farmUid/delete" action={deleteFarmAction}/>
                    </Route>

                    <Route
                        path="product_categories"
                        loader={productCategoriesLoader}
                        id="manageProductCategories"
                        handle={{crumb: ({pathname}) => <Crumb to={pathname}>Product Categories</Crumb>}}
                    >
                        <Route index element={<ManageProductCategories/>}/>

                        <Route path="create" element={<CreateProductCategory/>} action={createProductCategoryAction}/>

                        <Route
                            path=":productCategoryId"
                            element={<UpdateProductCategory/>}
                            action={updateProductCategoryAction}
                        />

                        <Route path=":productCategoryId/delete" action={deleteProductCategoryAction}/>
                    </Route>

                    <Route
                        path="products"
                        loader={productsLoader}
                        id="manageProducts"
                        handle={{crumb: ({pathname}) => <Crumb to={pathname}>Products</Crumb>}}
                    >
                        <Route index element={<ManageProducts/>}/>

                        <Route path="create" element={<CreateProduct/>} action={createProductAction}/>

                        <Route path=":productId" element={<UpdateProduct/>} action={updateProductAction}/>

                        <Route path=":productId/delete" action={deleteProductAction}/>
                    </Route>

                    <Route
                        path="reason_categories"
                        loader={reasonCategoriesLoader}
                        id="manageReasonCategories"
                        handle={{crumb: ({pathname}) => <Crumb to={pathname}>Reason Categories</Crumb>}}
                    >
                        <Route index element={<ManageReasonCategories/>}/>

                        <Route path="create" element={<CreateReasonCategory/>} action={createReasonCategoryAction}/>

                        <Route
                            path=":reasonCategoryId" element={<UpdateReasonCategory/>}
                            action={updateReasonCategoryAction}
                        />

                        <Route path=":reasonCategoryId/delete" action={deleteReasonCategoryAction}/>
                    </Route>

                    <Route
                        path="treatment_reasons"
                        loader={treatmentReasonsLoader}
                        id="manageTreatmentReasons"
                        handle={{crumb: ({pathname}) => <Crumb to={pathname}>Treatment Reasons</Crumb>}}
                    >
                        <Route index element={<ManageTreatmentReasons/>}/>

                        <Route path="create" element={<CreateTreatmentReason/>} action={createTreatmentReasonAction}/>

                        <Route
                            path=":treatmentReasonId"
                            element={<UpdateTreatmentReason/>}
                            action={updateTreatmentReasonAction}
                        />

                        <Route path=":treatmentReasonId/delete" action={deleteTreatmentReasonAction}/>
                    </Route>

                    <Route
                        path="cow_stages"
                        loader={cowStagesLoader}
                        id="manageCowStages"
                        handle={{crumb: ({pathname}) => <Crumb to={pathname}>Cow Stages</Crumb>}}
                    >
                        <Route index element={<ManageCowStages/>}/>

                        <Route path="create" element={<CreateCowStage/>} action={createCowStageAction}/>

                        <Route path=":cowStageId" element={<UpdateCowStage/>} action={updateCowStageAction}/>

                        <Route path=":cowStageId/delete" action={deleteCowStageAction}/>
                    </Route>

                    <Route
                        path="product_plans"
                        loader={productPlansLoader}
                        id="manageProductPlans"
                        handle={{crumb: ({pathname}) => <Crumb to={pathname}>Product Plans</Crumb>}}
                    >
                        <Route index element={<ManageProductPlans/>}/>

                        <Route path="create" element={<CreateProductPlan/>} action={createProductPlanAction}/>

                        <Route path=":productPlanId" element={<UpdateProductPlan/>} action={updateProductPlanAction}/>

                        <Route path=":productPlanId/delete" action={deleteProductPlanAction}/>
                    </Route>
                </Route>

                <Route
                    path="farms/:farmUid"
                    loader={farmLoader}
                    id="currentFarm"
                    handle={{
                        crumb: ({pathname, data}) => <Crumb to={pathname}>{data?.farm?.uid} {data?.farm?.name}</Crumb>
                    }}
                >
                    <Route index element={<Farm/>}/>

                    <Route
                        path="fertility_performances"
                        loader={farmFertilityPerformancesLoader}
                        id="farmFertilityPerformances"
                        handle={{crumb: ({pathname}) => <Crumb to={pathname}>Fertility Performance</Crumb>}}
                    >
                        <Route index element={<FarmFertilityPerformances/>}/>

                        <Route path="create"
                               element={<CreateFertilityPerformance/>}
                               action={createFertilityPerformanceAction}
                        />

                        <Route
                            path=":fertilityPerformanceId"
                            element={<UpdateFertilityPerformance/>}
                            action={updateFertilityPerformanceAction}
                        />

                        <Route path=":fertilityPerformanceId/delete" action={deleteFertilityPerformanceAction}/>
                    </Route>

                    <Route
                        path="culling_reasons"
                        loader={farmCullingReasonsLoader}
                        id="farmCullingReasons"
                        handle={{crumb: ({pathname}) => <Crumb to={pathname}>Culling Reasons</Crumb>}}
                    >
                        <Route index element={<FarmCullingReasons/>}/>

                        <Route path="create" element={<CreateCullingReason/>} action={createCullingReasonAction}/>

                        <Route path=":cullingReasonId" element={<UpdateCullingReason/>}
                               action={updateCullingReasonAction}/>

                        <Route path=":cullingReasonId/delete" action={deleteCullingReasonAction}/>
                    </Route>

                    <Route path="treatments" handle={{crumb: ({pathname}) => <Crumb to={pathname}>Treatments</Crumb>}}>
                        <Route index element={<FarmTreatments/>} loader={farmTreatmentsLoader}/>
                    </Route>

                    <Route
                        path="antibiotic_usages"
                        loader={farmAntibioticUsagesLoader}
                        id="farmAntibioticUsages"
                        handle={{crumb: ({pathname}) => <Crumb to={pathname}>Antibiotic Usage</Crumb>}}
                    >
                        <Route index element={<FarmAntibioticUsages/>} action={createAntibioticUsageAction}/>

                        <Route path=":treatmentId" element={<UpdateAntibioticUsage/>}
                               action={updateAntibioticUsageAction}/>

                        <Route path=":treatmentId/delete" action={deleteAntibioticUsageAction}/>
                    </Route>

                    <Route
                        path="reactive_treatments"
                        loader={farmReactiveTreatmentsLoader}
                        id="farmReactiveTreatments"
                        handle={{crumb: ({pathname}) => <Crumb to={pathname}>Reactive Treatments</Crumb>}}
                    >
                        <Route index element={<FarmReactiveTreatments/>} action={creatReactiveTreatmentsAction}/>

                        <Route
                            path=":treatmentId"
                            element={<UpdateReactiveTreatment/>}
                            action={updateReactiveTreatmentAction}
                        />

                        <Route path=":treatmentId/delete" action={deleteReactiveTreatmentAction}/>
                    </Route>

                    <Route
                        path="proactive_treatments"
                        loader={farmProactiveTreatmentsLoader}
                        id="farmProactiveTreatments"
                        handle={{crumb: ({pathname}) => <Crumb to={pathname}>Proactive Treatments</Crumb>}}
                    >
                        <Route index element={<FarmProactiveTreatments/>} action={createProactiveTreatmentsAction}/>

                        <Route
                            path=":treatmentId"
                            element={<UpdateProactiveTreatment/>}
                            action={updateProactiveTreatmentAction}
                        />

                        <Route path=":treatmentId/delete" action={deleteProactiveTreatmentAction}/>
                    </Route>
                </Route>
            </Route>
        </Route>)
    ), []);

    return <RouterProvider router={router}/>;
}

export default App;
