import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {useState} from "react";
import {ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon, PlusIcon} from "@heroicons/react/24/outline";
import {debounce} from "lodash";
import Jumbotron from "../Layout/Jumbotron";
import BasicPrimaryButton from "../Button/BasicPrimaryButton";
import Pagination from "./Pagination";
import BasicSelect from "../Input/BasicSelect";

const showOptions = [
    {value: 10},
    {value: 20},
    {value: 30},
    {value: 40},
    {value: 50},
];

const DataTable = ({data, columns, useFilter, onRowClick, title, onCreateClick}) => {
    const [sorting, setSorting] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            globalFilter,
        },
        globalFilterFn: (row, columnId, filterValue) => {
            const safeValue = (() => {
                const value = row.getValue(columnId);
                return typeof value === "number" ? String(value) : value;
            })();

            return safeValue?.toLowerCase().includes(filterValue.toLowerCase());
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    return <Jumbotron>
        <div className="p-4 sm:p-8 pb-0 flex flex-col sm:flex-row flex-wrap items-center justify-between">
            <h2 className="text-2xl font-medium tracking-tight text-gray-900 dark:text-white">
                <div className="flex">
                    <span className="mr-1">{title}</span>
                    <span className="text-sm">({table.getFilteredRowModel().rows.length})</span>
                </div>
            </h2>

            <div className="flex flex-col sm:flex-row justify-end items-center sm:space-x-4 w-full max-w-md">
                {useFilter && <div className="group relative flex mt-4 sm:mt-0 w-full">
                    <input value={globalFilter ?? ""}
                           onChange={debounce((e) => setGlobalFilter(String(e.target.value)), 500, {leading: true})}
                           className="pl-4 pr-10 py-2 sm:text-sm border rounded-md w-full
                           outline-none focus:border-cyan-500 hover:border-gray-300 dark:focus:border-cyan-400
                           dark:bg-gray-800 dark:border-gray-600 dark:hover:border-gray-400 dark:text-white"/>
                    <MagnifyingGlassIcon className="absolute right-3 top-0 h-full w-5 dark:stroke-white"/>
                </div>}
                {onCreateClick && <div className="mt-4 sm:mt-0">
                    <BasicPrimaryButton onClick={onCreateClick}>
                        <PlusIcon className="h-5 w-5 mr-2"/>
                        Create
                    </BasicPrimaryButton>
                </div>}
            </div>
        </div>

        <div className="overflow p-4 sm:p-8 sm:pt-0">
            <table className="table-auto border-collapse w-full text-sm">
                <thead className="bg-white dark:bg-gray-800 border-b-2 sm:border-b dark:border-gray-500">
                {table.getHeaderGroups().map(headerGroup =>
                    <tr key={headerGroup.id} className="hidden sm:table-row">
                        {headerGroup.headers.map(header => (
                            <th key={header.id} className="font-medium p-4 pl-0 pt-0 pb-1 sm:pb-3
                                dark:text-white text-left block sm:table-cell">
                                {header.isPlaceholder ?
                                    null :
                                    <div {...{
                                        className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                                        onClick: header.column.getToggleSortingHandler(),
                                    }}>
                                        <span className="flex">
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {{
                                                asc: <ChevronUpIcon className="h-5 w-5"/>,
                                                desc: <ChevronDownIcon className="h-5 w-5"/>,
                                            }[header.column.getIsSorted()] ?? null}
                                        </span>
                                    </div>
                                }
                            </th>
                        ))}
                    </tr>
                )}
                </thead>

                <tbody className="border-t sm:border-t-0 dark:border-gray-500">
                {table.getRowModel().rows.map(row =>
                    <tr key={row.id} {...{
                        className: `block sm:table-row border-b-2 sm:border-b-0 dark:border-gray-500 ${onRowClick ?
                            "group cursor-pointer select-none hover:bg-green-50 dark:hover:bg-gray-900" : ""}`,
                        onClick: onRowClick ? () => onRowClick(row.original) : null,
                    }}>
                        {row.getVisibleCells().map(cell => (
                            cell.getContext().getValue()?.toString()?.length
                                ? <td key={cell.id} className="sm:border-b border-gray-100 dark:border-gray-600
                                    sm:pr-4 py-1 sm:py-4 text-gray-500 dark:text-gray-400
                                    dark:group-hover:text-green-400 block sm:table-cell">
                                    <div className="sm:hidden font-medium text-black dark:text-white">
                                        {cell.column.columnDef.header}
                                    </div>

                                    <div className="sm:line-clamp-3 sm:max-w-xs">
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                </td>
                                : <td key={cell.id} className="sm:border-b border-gray-100 dark:border-gray-600
                                block sm:table-cell"/>
                        ))}
                    </tr>
                )}
                </tbody>
            </table>

            <div className="flex flex-col sm:flex-row justify-between items-center mt-4 space-y-4">
                <div className="flex items-center space-x-4">
                    <span className="dark:text-white">Show:</span>
                    <BasicSelect
                        value={{value: table.getState().pagination.pageSize}}
                        onChange={option => table.setPageSize(Number(option.value))}
                        options={showOptions} optionValue="value" optionLabel="value"
                        isClearable={false} isSearchable={false}
                    />
                </div>

                <Pagination activePage={table.getState().pagination.pageIndex + 1} totalPages={table.getPageCount()}
                            onPageChange={p => table.setPageIndex(p - 1)}/>
            </div>
        </div>
    </Jumbotron>
}

export default DataTable;