import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import TreatmentReasonForm, {formDataToTreatmentReason} from "./TreatmentReasonForm";
import TreatmentReasons from "../../../services/UkTreatmentApi/TreatmentReasons";
import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";

export async function action({request, params}) {
    const formData = await request.formData();
    const treatmentReason = formDataToTreatmentReason(formData);
    treatmentReason.categoryId = treatmentReason.category.id;

    try {
        await TreatmentReasons.update(params.treatmentReasonId, treatmentReason);

        return redirect("/manage/treatment_reasons");
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {categories, getTreatmentReason} = useRouteLoaderData("manageTreatmentReasons");
    const {treatmentReasonId} = useParams();
    const actionError = useActionData();

    const treatmentReason = useMemo(() => getTreatmentReason(treatmentReasonId), [getTreatmentReason, treatmentReasonId]);

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    const text = `Edit the treatment reason. 
Assign the correct category. 

Tick the retired box if you want to hide this.
Delete if you accidentally created this and nothing else references it. (Else you have to delete all that references it)`;

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="TreatmentReason" text={text}/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <TreatmentReasonForm treatmentReason={treatmentReason} categories={categories}
                                 id="edit-treatment-reason-form"/>
        </div>
    </FormContainer>;
}

export default Update;