import BasicPrimaryButton from "../../../components/Button/BasicPrimaryButton";
import {useLocation, useNavigation, useSubmit} from "react-router-dom";
import BasicErrorButton from "../../../components/Button/BasicErrorButton";
import * as yup from "yup";
import {Formik} from "formik";
import FormikTextInput from "../../../components/Input/FormikTextInput";
import FormikSwitch from "../../../components/Input/FormikSwitch";
import FormikSelect from "../../../components/Input/FormikSelect";

const categorySchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
});
const treatmentReasonSchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string().trim()
        .required("Name is required")
        .max(255, "Must be 255 characters or less"),
    category: categorySchema
        .required("Category is required"),
    retired: yup.boolean(),
});

export function formDataToTreatmentReason(formData) {
    const treatmentReason = Object.fromEntries(formData);
    treatmentReason.category = JSON.parse(treatmentReason.category);

    return treatmentReasonSchema.cast(treatmentReason);
}

const TreatmentReasonForm = ({treatmentReason, categories, ...props}) => {
    const navigation = useNavigation();
    const submit = useSubmit();
    const location = useLocation();

    const handleDelete = event => {
        if (!window.confirm("Please confirm you want to delete")) event.preventDefault();
        else submit(null, {action: `${location.pathname}/delete`, method: "post"});
    }

    return <Formik
        initialValues={{
            name: treatmentReason?.name || "",
            category: treatmentReason?.category || null,
            retired: treatmentReason?.retired || false,
        }}
        validationSchema={treatmentReasonSchema}
        onSubmit={async values => submit({...values, category: JSON.stringify(values.category)}, {method: "post"})}
    >
        {formik => <form className="px-4 sm:px-6" method="post" onSubmit={formik.handleSubmit} {...props}>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                    <FormikSelect
                        name="category" label="Category" options={categories} optionValue="id" optionLabel="name"
                        isClearable={false}
                    />
                </div>

                <div className="col-span-12">
                    <FormikTextInput label="Reason" name="name" type="text"/>
                </div>

                {treatmentReason && <div className="col-span-12 sm:col-span-1">
                    <FormikSwitch label="Retired" name="retired"/>
                </div>}
            </div>

            <div className="mt-4 py-3 flex justify-between">
                <BasicPrimaryButton disabled={navigation.state === "submitting"} type="submit">
                    Submit
                </BasicPrimaryButton>

                {treatmentReason &&
                    <BasicErrorButton onClick={handleDelete} disabled={navigation.state === "submitting"}>
                        Delete
                    </BasicErrorButton>}
            </div>
        </form>}
    </Formik>;
}

export default TreatmentReasonForm;