import BasicPrimaryButton from "../../../components/Button/BasicPrimaryButton";
import {useLocation, useNavigation, useSubmit} from "react-router-dom";
import BasicErrorButton from "../../../components/Button/BasicErrorButton";
import * as yup from "yup";
import {Formik} from "formik";
import FormikTextInput from "../../../components/Input/FormikTextInput";

const productCategorySchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string().trim()
        .required("Name is required")
        .max(255, "Must be 255 characters or less"),
});

export function formDataToProductCategory(formData) {
    const productCategory = Object.fromEntries(formData);

    return productCategorySchema.cast(productCategory);
}

const ProductCategoryForm = ({productCategory, ...props}) => {
    const navigation = useNavigation();
    const submit = useSubmit();
    const location = useLocation();

    const handleDelete = event => {
        if (!window.confirm("Please confirm you want to delete")) event.preventDefault();
        else submit(null, {action: `${location.pathname}/delete`, method: "post"});
    }

    return <Formik
        initialValues={{
            name: productCategory?.name || "",
        }}
        validationSchema={productCategorySchema}
        onSubmit={async values => submit(values, {method: "post"})}
    >
        {formik => <form className="px-4 sm:px-6" method="post" onSubmit={formik.handleSubmit} {...props}>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                    <FormikTextInput label="Category Name" name="name" type="text"/>
                </div>
            </div>

            <div className="mt-4 py-3 flex justify-between">
                <BasicPrimaryButton disabled={navigation.state === "submitting"} type="submit">
                    Submit
                </BasicPrimaryButton>

                {productCategory &&
                    <BasicErrorButton onClick={handleDelete} disabled={navigation.state === "submitting"}>
                        Delete
                    </BasicErrorButton>}
            </div>
        </form>}
    </Formik>;
}

export default ProductCategoryForm;