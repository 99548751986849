import Section from "../../components/Layout/Section";
import Container from "../../components/Layout/Container";
import Description from "../../components/Layout/Description";
import LinkTile from "../../components/Navigation/LinkTile";
import Farms from "../../services/UkTreatmentApi/Farms";
import {sortBy} from "lodash";

const navItems = [
    {
        title: "Treatment",
        text: "Show treatments across programs",
        to: "treatments",
    },
    {
        title: "Data Collection - Proactive Treatments",
        text: "Herd Health Plan",
        to: "proactive_treatments",
    },
    {
        title: "Data Collection - Reactive Treatments",
        text: "Health Challenges",
        to: "reactive_treatments",
    },
    {
        title: "Data Collection - Antibiotic Usage",
        text: "",
        to: "antibiotic_usages",
    },
    {
        title: "Data Collection - Culling Reasons",
        text: "",
        to: "culling_reasons",
    },
    {
        title: "Data Collection - Fertility Performance",
        text: "",
        to: "fertility_performances",
    },
];

export async function loader({params}) {
    const farmResponse = await Farms.single(params.farmUid);
    const farm = farmResponse.data;
    farm.users = sortBy(farm.users, ["name"]);

    return {farm};
}

const Farm = () =>
    <Section>
        <Container>
            <Description title="Herd Health Plan" text="Working together for optimal animal health"/>

            <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-6 text-sm
                sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3">
                {navItems.map(item => <LinkTile key={item.title} item={item}/>)}
            </div>
        </Container>
    </Section>

export default Farm;