import {useState} from "react";
import {DarkModeSwitch} from "react-toggle-dark-mode";
import useDarkSide from "../../hooks/useDarkSide";

const Switcher = () => {
    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState(colorTheme === "light");

    const toggleDarkMode = (checked) => {
        setTheme(colorTheme);
        setDarkSide(checked);
    };

    return <div className="rounded-md p-2 hover:bg-green-50 dark:hover:bg-gray-700">
        <DarkModeSwitch className="h-6 w-6" onChange={toggleDarkMode} checked={darkSide}/>
    </div>
}

export default Switcher;