import {useField, useFormikContext} from "formik";
import BasicInput from "./BasicInput";

const FormikRadioWithOtherText = ({options, otherName, ...props}) => {
    const [field] = useField(props);
    const [otherField] = useField({name: otherName, type: "text"});
    const {setFieldValue} = useFormikContext();

    return <div>
        {options.map(o =>
            <div className="flex items-center mt-4" key={o}>
                <input
                    id={`${props.name}-option-${o}`}
                    type="radio"
                    className="h-4 w-4 accent-green-400"
                    value={o}
                    checked={field.value === o}
                    onChange={({target: {value, name}}) => setFieldValue(name, value)}
                    {...props}/>

                <label htmlFor={`${props.name}-option-${o}`} className="text-sm font-medium ml-2
                text-gray-700 dark:text-white">
                    {o}
                </label>
            </div>
        )}

        <div className="flex items-center mt-2">
            <input
                id={`${props.name}-option-Other`}
                type="radio"
                className="h-4 w-4 accent-green-400"
                value="Other"
                checked={field.value === "Other"}
                onChange={({target: {value, name}}) => setFieldValue(name, value)}
                {...props}/>

            <div className="flex items-center w-full">
                <label htmlFor={`${props.name}-option-Other`} className="text-sm font-medium ml-2 mr-4
                text-gray-700 dark:text-white">
                    Other:
                </label>

                <BasicInput name={otherName} disabled={field.value !== "Other"} {...otherField}/>
            </div>
        </div>
    </div>;
}

export default FormikRadioWithOtherText;