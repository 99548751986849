import Datepicker from "react-tailwindcss-datepicker";
import {useField, useFormikContext} from "formik";
import {useState} from "react";
import BasicLabel from "./BasicLabel";
import moment from "moment-timezone";
import FormikError from "./FormikError";

const FormikDatePicker = ({label, ...props}) => {
    const [field] = useField(props);
    const {setFieldValue} = useFormikContext();

    const [value, setValue] = useState({
        startDate: moment(field.value).format("YYYY-MM-DD"),
        endDate: moment(field.value).format("YYYY-MM-DD"),
    });

    return <>
        <BasicLabel htmlFor={props.id || props.name}>{label}</BasicLabel>
        <Datepicker
            primaryColor="green"
            inputClassName="w-full mt-1 !rounded-md shadow-sm !text-base sm:!text-sm outline-none focus:ring-0
            !border-gray-200 focus:!border-cyan-500 dark:focus:!border-cyan-400 hover:!border-gray-300
            dark:!bg-gray-800 dark:!border-gray-600 dark:hover:!border-gray-400
            dark:!text-white border !py-2 !pl-3 transition-none !font-normal !tracking-normal cursor-pointer"
            toggleClassName="absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40
            disabled:cursor-not-allowed pt-1"
            asSingle={true}
            useRange={false}
            readOnly={true}
            value={value}
            onChange={value => {
                setValue(value);
                setFieldValue(props.name, moment(value.startDate, "YYYY-MM-DD").valueOf());
            }}
            {...props}
        />
        <FormikError {...props}/>
        <input type="hidden" name={props.name} value={moment(value.startDate, "YYYY-MM-DD").valueOf()}/>
    </>;
}

export default FormikDatePicker;