import {createColumnHelper} from "@tanstack/react-table";
import {useNavigate, useRouteLoaderData} from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import Container from "../../../components/Layout/Container";
import {default as ApiFarms} from "../../../services/UkTreatmentApi/Farms";
import Users from "../../../services/UkTreatmentApi/Users";
import {sortBy} from "lodash";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor("uid", {
        header: "UID",
    }),
    columnHelper.accessor("name", {
        header: "Farm Name",
    }),
    columnHelper.accessor("address", {
        header: "Address",
    }),
    columnHelper.accessor(value => value.users.map(u => u.name).join(", "), {
        header: "Users",
    }),
];

export async function loader() {
    const [farms, users] = await Promise.all([
        ApiFarms.index().then(({data}) => sortBy(data, ["name", "uid"])),
        Users.index().then(({data}) => sortBy(data.map(u => ({
            ...u,
            name: u.name ? `${u.name} (${u.email})` : `? (${u.email})`,
        })), ["name"])),
    ]);

    farms.forEach(f => f.users = sortBy(
        f.users.map(sub => users.find(u => u.sub === sub)).filter(sub => sub),
        ["name"]));

    const getFarm = (uid) => {
        const farm = farms.find(c => c.uid === uid);
        if (!farm) throw new Error(`Couldn't find farm with uid \`${uid}\``);

        return farm;
    }

    return {farms, users, getFarm};
}

const Farms = () => {
    const {farms} = useRouteLoaderData("root");
    const navigate = useNavigate();

    return <Container>
        <DataTable title="Farms" data={farms} columns={columns} useFilter={true}
                   onCreateClick={() => navigate("create")} onRowClick={(row) => navigate(row.uid)}/>
    </Container>;
}

export default Farms;