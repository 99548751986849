import {destroy, get, post, put} from "./base";

const Farms = {
    index: () => get("/farms"),
    create: (body) => post("/farms", body),
    single: (uid) => get(`/farms/${uid}`),
    update: (uid, body) => put(`/farms/${uid}`, body),
    delete: (uid) => destroy(`/farms/${uid}`),
};

export default Farms;