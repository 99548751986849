import Section from "../../components/Layout/Section";
import Container from "../../components/Layout/Container";
import Description from "../../components/Layout/Description";
import LinkTile from "../../components/Navigation/LinkTile";


const navItems = [
    {
        title: "Farms",
        text: "Manage farms and assign users",
        to: "farms",
    },
    {
        title: "Product Categories",
        text: "Manage product categories such as AHV Product",
        to: "product_categories",
    },
    {
        title: "Products",
        text: "Manage products and assign categories",
        to: "products",
    },
    {
        title: "Reason categories",
        text: "Manage reason categories such as Udder Health",
        to: "reason_categories",
    },
    {
        title: "Treatment reasons",
        text: "Manage reasons and assign categories",
        to: "treatment_reasons",
    },
    {
        title: "Cow Stages",
        text: "Manage cow stages such as Pre-Calving",
        to: "cow_stages",
    },
    {
        title: "Product Plans",
        text: "Manage product plans and assign cow stages",
        to: "product_plans",
    },
];

const Root = () =>
    <Section>
        <Container>
            <Description title="Manage Database Objects" text="Creating users should be done in AWS Cognito"/>

            <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-6 text-sm
                sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3">
                {navItems.map(item => <LinkTile key={item.title} item={item}/>)}
            </div>
        </Container>
    </Section>

export default Root;