import {createColumnHelper} from "@tanstack/react-table";
import {useNavigate, useRouteLoaderData} from "react-router-dom";
import Container from "../../../components/Layout/Container";
import DataTable from "../../../components/DataTable/DataTable";
import moment from "moment-timezone";
import {default as ApiFertilityPerformances} from "../../../services/UkTreatmentApi/FertilityPerformances";
import {sortBy} from "lodash";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor(value => moment(value.scanningDate).format("YYYY-MM-DD"), {
        header: "Date",
        sortingFn: "datetime",
    }),
    columnHelper.accessor("nCowsScanned", {
        header: "No. Scanned",
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("nCowsPregnant", {
        header: "No. Pregnant",
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("reason", {
        header: "Reason Empty Cows",
    }),
];

export async function loader({params}) {
    const fertilityPerformances = await ApiFertilityPerformances.ofFarm(params.farmUid)
        .then(({data}) => sortBy(data, ["scanningDate"]));

    const getFertilityPerformance = (id) => {
        const fertilityPerformance = fertilityPerformances.find(r => r.id === parseInt(id));
        if (!fertilityPerformance) throw new Error(`Couldn't find fertility performance with id \`${id}\``);

        return fertilityPerformance;
    }

    return {fertilityPerformances, getFertilityPerformance};
}

const FertilityPerformances = () => {
    const {fertilityPerformances} = useRouteLoaderData("farmFertilityPerformances");
    const navigate = useNavigate();

    return <Container>
        <DataTable title="Fertility Performance" data={fertilityPerformances} columns={columns}
                   onCreateClick={() => navigate("create")} onRowClick={(row) => navigate(row.id.toString())}/>
    </Container>;
}

export default FertilityPerformances;