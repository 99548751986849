import * as yup from "yup";
import {useLocation, useNavigation, useSubmit} from "react-router-dom";
import {Formik} from "formik";
import BasicPrimaryButton from "../../../components/Button/BasicPrimaryButton";
import FormikDatePicker from "../../../components/Input/FormikDatePicker";
import FormikTextInput from "../../../components/Input/FormikTextInput";
import BasicErrorButton from "../../../components/Button/BasicErrorButton";
import FormikSelect from "../../../components/Input/FormikSelect";

const productCategorySchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
});
const productSchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
    defaultUnit: yup.string(),
    category: productCategorySchema,
    retired: yup.boolean(),
});
const reasonCategorySchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
});
const reasonSchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
    category: reasonCategorySchema,
    retired: yup.boolean(),
});
const reactiveTreatmentSchema = yup.object({
    id: yup.number().positive().integer(),
    program: yup.string().required(),
    farmUid: yup.string().required(),
    cowNumber: yup.string().trim()
        .required("Cow Number is required"),
    treatmentDate: yup.number().positive().integer()
        .required("Treatment Date is required"),
    product: productSchema
        .required("Product is required"),
    dosage: yup.number().positive().integer()
        .required("Dosage is required"),
    unit: yup.string().trim()
        .required("Unit is required"),
    reason: reasonSchema.nullable().default(null),
    comments: yup.string().trim()
        .max(511, "Must be 511 characters or less"),
});

export function formDataToReactiveTreatment(formData) {
    const reactiveTreatment = Object.fromEntries(formData);
    reactiveTreatment.product = JSON.parse(reactiveTreatment.product);
    reactiveTreatment.reason = JSON.parse(reactiveTreatment.reason);

    if (!reactiveTreatment.reason) delete reactiveTreatment.reason;

    return reactiveTreatmentSchema.cast(reactiveTreatment);
}

const ReactiveTreatmentUpdateForm = ({farm, reactiveTreatment, products, reasons, ...props}) => {
    const navigation = useNavigation();
    const submit = useSubmit();
    const location = useLocation();

    const handleDelete = event => {
        if (!window.confirm("Please confirm you want to delete")) event.preventDefault();
        else submit(null, {action: `${location.pathname}/delete`, method: "post"});
    }

    return <Formik
        initialValues={{
            program: "Health Challenges",
            farmUid: farm.uid,
            cowNumber: reactiveTreatment.cowNumber,
            treatmentDate: reactiveTreatment.treatmentDate,
            product: {
                ...reactiveTreatment.product,
                defaultUnit: reactiveTreatment.unit || reactiveTreatment.product.defaultUnit
            },
            dosage: reactiveTreatment.dosage,
            unit: reactiveTreatment.unit,
            reason: reactiveTreatment.reason,
            comments: reactiveTreatment.comments,
        }}
        validationSchema={reactiveTreatmentSchema}
        onSubmit={async values => submit({
            ...values,
            product: JSON.stringify(values.product),
            reason: JSON.stringify(values.reason),
        }, {method: "post"})}
    >
        {formik => <form className="px-4 sm:px-6" method="post" onSubmit={formik.handleSubmit} {...props}>
            <div className="grid grid-cols-12 gap-y-6 gap-x-2 sm:gap-6">
                <div className="col-span-12 sm:col-span-6">
                    <FormikDatePicker label="Treatment Date" name="treatmentDate"/>
                </div>

                <div className="col-span-12 sm:col-span-6">
                    <FormikTextInput label="Cow Number" name="cowNumber" type="text"/>
                </div>

                <div className="col-span-12 sm:col-span-6">
                    <FormikSelect
                        name="product" label="Product" options={products} optionValue="id" optionLabel="name"
                        groupKey="category.name" isClearable={false}
                    />
                </div>

                <div className="col-span-6 sm:col-span-3">
                    <FormikTextInput label="Dosage" name="dosage" type="number" min={1}/>
                </div>

                <div className="col-span-6 sm:col-span-3">
                    <FormikTextInput label="Unit" name="unit" type="text"/>
                </div>

                <div className="col-span-12">
                    <FormikSelect
                        name="reason" label="Reason" options={reasons} optionValue="id" optionLabel="name"
                        groupKey="category.name"
                    />
                </div>

                <div className="col-span-12">
                    <FormikTextInput label="Comments" name="comments" as="textarea"/>
                </div>
            </div>

            <div className="mt-4 py-3 flex justify-between">
                <BasicPrimaryButton disabled={navigation.state === "submitting"} type="submit">
                    Submit
                </BasicPrimaryButton>

                <BasicErrorButton onClick={handleDelete} disabled={navigation.state === "submitting"}>
                    Delete
                </BasicErrorButton>
            </div>
        </form>}
    </Formik>;
}

export default ReactiveTreatmentUpdateForm;