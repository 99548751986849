import {createColumnHelper} from "@tanstack/react-table";
import {useNavigate, useRouteLoaderData} from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import Container from "../../../components/Layout/Container";
import {default as ApiProductPlans} from "../../../services/UkTreatmentApi/ProductPlans";
import {sortBy} from "lodash";
import ProductCategories from "../../../services/UkTreatmentApi/ProductCategories";
import Products from "../../../services/UkTreatmentApi/Products";
import CowStages from "../../../services/UkTreatmentApi/CowStages";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor("id", {
        header: "ID",
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("cowStage.name", {
        header: "Stage Name",
    }),
    columnHelper.accessor("product.name", {
        header: "Product Name",
    }),
    columnHelper.accessor("dosage", {
        header: "Dosage",
        cell: info => `${info.getValue()} ${info.row.original.unit}`,
        sortingFn: "alphanumeric",
    }),
];

export async function loader() {
    let [productPlans, products, categories, cowStages] = await Promise.all([
        ApiProductPlans.index().then(({data}) => data),
        Products.index().then(({data}) => sortBy(data, ["name"])),
        ProductCategories.index().then(({data}) => data),
        CowStages.index().then(({data}) => sortBy(data, ["name"])),
    ]);

    products.forEach(p => p.category = categories.find(c => c.id === p.categoryId));
    productPlans = sortBy(
        productPlans.map(p => ({
            ...p,
            cowStage: cowStages.find(s => s.id === p.cowStageId),
            product: products.find(product => product.id === p.productId),
        })),
        ["cowStage.name", "product.name"]
    );

    const getProductPlan = (id) => {
        const productPlan = productPlans.find(c => c.id === parseInt(id));
        if (!productPlan) throw new Error(`Couldn't find product plan with id \`${id}\``);

        return productPlan;
    }

    cowStages = cowStages.filter(s => !s.retired);
    products = products.filter(p => !p.retired);

    return {productPlans, cowStages, products, getProductPlan};
}

const ProductPlans = () => {
    const {productPlans} = useRouteLoaderData("manageProductPlans");
    const navigate = useNavigate();

    return <Container>
        <DataTable title="Product Plans" data={productPlans} columns={columns} useFilter={true}
                   onCreateClick={() => navigate("create")} onRowClick={(row) => navigate(row.id.toString())}/>
    </Container>;
}

export default ProductPlans;