import {PlusIcon} from "@heroicons/react/24/outline";

const MultipleInputHeader = ({header, addOnClick}) =>
    <div className="flex justify-between items-center">
        <span className="font-medium text-gray-700 dark:text-gray-400">
            {header}
        </span>

        <PlusIcon onClick={addOnClick} className="h-6 w-6 cursor-pointer text-green-400 dark:text-green-600
        hover:text-green-600 dark:hover:text-green-400"/>
    </div>

export default MultipleInputHeader;