import {useField, useFormikContext} from "formik";
import BasicLabel from "./BasicLabel";
import BasicSelect from "./BasicSelect";
import FormikError from "./FormikError";

const FormikSelect = ({label, ...props}) => {
    const [field] = useField(props);
    const {setFieldValue} = useFormikContext();

    return <>
        <BasicLabel htmlFor={props.id || props.name}>{label}</BasicLabel>
        <BasicSelect value={field.value} onChange={value => setFieldValue(props.name, value)} isClearable {...props}/>
        <FormikError name={props.name}/>
    </>;
}

export default FormikSelect;