import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import ProductForm, {formDataToProduct} from "./ProductForm";
import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";
import Products from "../../../services/UkTreatmentApi/Products";

export async function action({request, params}) {
    const formData = await request.formData();
    const product = formDataToProduct(formData);
    product.categoryId = product.category.id;

    try {
        await Products.update(params.productId, product);

        return redirect("/manage/products");
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {categories, getProduct} = useRouteLoaderData("manageProducts");
    const {productId} = useParams();
    const actionError = useActionData();

    const product = useMemo(() => getProduct(productId), [getProduct, productId]);

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    const text = `Edit the product. 
Assign the correct category. 

Tick the retired box if you want to hide this.
Delete if you accidentally created this and nothing else references it. (Else you have to delete all that references it)`;

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Product" text={text}/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <ProductForm product={product} categories={categories} id="edit-product-form"/>
        </div>
    </FormContainer>;
}

export default Update;