import {destroy, get, post, put} from "./base";

const Treatments = {
    index: () => get("/treatments"),
    create: (body) => post("/treatments", body),
    single: (id) => get(`/treatments/${id}`),
    update: (id, body) => put(`/treatments/${id}`, body),
    delete: (id) => destroy(`/treatments/${id}`),
    ofFarm: (uid) => get(`/treatments_of_farm/${uid}`),
    ofFarmWithProgram: (uid, program) => get(`/treatments_of_farm/${uid}/program/${program}`),
};

export default Treatments;