import {Field} from "formik";

const BasicInput = (props) =>
    <Field className="mt-1 block w-full rounded-md shadow-sm sm:text-sm outline-none
                      focus:border-cyan-500 dark:focus:border-cyan-400 hover:border-gray-300 disabled:border-gray-200
                      dark:bg-gray-800 dark:border-gray-600 dark:hover:border-gray-400
                      dark:hover:disabled:border-gray-600 dark:disabled:bg-gray-900 dark:text-white
                      disabled:cursor-not-allowed border py-2 px-3" {...props}/>

export default BasicInput;
