import * as yup from "yup";
import {useLocation, useNavigation, useSubmit} from "react-router-dom";
import {Formik} from "formik";
import BasicPrimaryButton from "../../../components/Button/BasicPrimaryButton";
import BasicErrorButton from "../../../components/Button/BasicErrorButton";
import FormikTextInput from "../../../components/Input/FormikTextInput";
import FormikSelect from "../../../components/Input/FormikSelect";

const productCategorySchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
});
const productSchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
    defaultUnit: yup.string(),
    category: productCategorySchema,
    retired: yup.boolean(),
});
const cowStageSchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
    retired: yup.boolean(),
});
const productPlanSchema = yup.object({
    id: yup.number().positive().integer(),
    cowStage: cowStageSchema
        .required("Cow Stage is required"),
    product: productSchema
        .required("Product is required"),
    dosage: yup.number().positive().integer()
        .required("Dosage is required"),
    unit: yup.string().trim()
        .required("Unit is required"),
});

export function formDataToProductPlan(formData) {
    const productPlan = Object.fromEntries(formData);
    productPlan.cowStage = JSON.parse(productPlan.cowStage);
    productPlan.product = JSON.parse(productPlan.product);

    return productPlanSchema.cast(productPlan);
}

const ProductPlanUpdateForm = ({productPlan, cowStages, products, ...props}) => {
    const navigation = useNavigation();
    const submit = useSubmit();
    const location = useLocation();

    const handleDelete = event => {
        if (!window.confirm("Please confirm you want to delete")) event.preventDefault();
        else submit(null, {action: `${location.pathname}/delete`, method: "post"});
    }

    return <Formik
        initialValues={{
            cowStage: productPlan.cowStage,
            product: {...productPlan.product, defaultUnit: productPlan.unit || productPlan.product.defaultUnit},
            dosage: productPlan.dosage,
            unit: productPlan.unit,
        }}
        validationSchema={productPlanSchema}
        onSubmit={async values => submit({
            ...values,
            cowStage: JSON.stringify(values.cowStage),
            product: JSON.stringify(values.product),
        }, {method: "post"})}
    >
        {formik => <form className="px-4 sm:px-6" method="post" onSubmit={formik.handleSubmit} {...props}>
            <div className="grid grid-cols-12 gap-y-6 gap-x-2 sm:gap-6">
                <div className="col-span-12">
                    <FormikSelect
                        name="cowStage" label="Cow Stage" options={cowStages} optionValue="id" optionLabel="name"
                        isClearable={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6">
                    <FormikSelect
                        name="product" label="Product" options={products} optionValue="id" optionLabel="name"
                        groupKey="category.name" isClearable={false}
                    />
                </div>

                <div className="col-span-6 sm:col-span-3">
                    <FormikTextInput label="Dosage" name="dosage" type="number" min={1}/>
                </div>

                <div className="col-span-6 sm:col-span-3">
                    <FormikTextInput label="Unit" name="unit" type="text"/>
                </div>
            </div>

            <div className="mt-4 py-3 flex justify-between">
                <BasicPrimaryButton disabled={navigation.state === "submitting"} type="submit">
                    Submit
                </BasicPrimaryButton>

                <BasicErrorButton onClick={handleDelete} disabled={navigation.state === "submitting"}>
                    Delete
                </BasicErrorButton>
            </div>
        </form>}
    </Formik>;
}

export default ProductPlanUpdateForm;