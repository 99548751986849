import {redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import CullingReasonForm, {formDataToCullingReason} from "./CullingReasonForm";
import FormContainer from "../../../components/Layout/FormContainer";
import {useContext, useEffect, useMemo} from "react";
import AlertContext from "../../../contexts/AlertContext";
import CullingReasons from "../../../services/UkTreatmentApi/CullingReasons";

export async function action({request, params}) {
    const formData = await request.formData();
    const cullingReason = formDataToCullingReason(formData);

    try {
        await CullingReasons.update(params.cullingReasonId, cullingReason);

        return redirect(`/farms/${params.farmUid}/culling_reasons`);
    } catch (error) {
        return error;
    }
}

const Update = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const {getCullingReason} = useRouteLoaderData("farmCullingReasons");
    const {cullingReasonId} = useParams();
    const actionError = useActionData();
    const {farm} = useRouteLoaderData("currentFarm");

    const cullingReason = useMemo(() => getCullingReason(cullingReasonId), [getCullingReason, cullingReasonId])

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission");
    }, [actionError, handleErrorAlert]);

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Culling Reason" text="Edit the culling reason."/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <CullingReasonForm farm={farm} cullingReason={cullingReason} id="edit-culling-reason-form"/>
        </div>
    </FormContainer>;
}

export default Update;