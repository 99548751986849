import {redirect, useActionData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import ProductCategoryForm, {formDataToProductCategory} from "./ProductCategoryForm";
import ProductCategories from "../../../services/UkTreatmentApi/ProductCategories";
import {useContext, useEffect} from "react";
import AlertContext from "../../../contexts/AlertContext";

export async function action({request}) {
    const formData = await request.formData();
    const productCategory = formDataToProductCategory(formData);

    try {
        await ProductCategories.create(productCategory);

        return redirect("/manage/product_categories");
    } catch (error) {
        return error;
    }
}

const Create = () => {
    const {handleErrorAlert} = useContext(AlertContext);
    const actionError = useActionData();

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription
                title="Product Category"
                text="Register the product category in the app."
            />
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <ProductCategoryForm id="create-product-category-form"/>
        </div>
    </FormContainer>;
}

export default Create;