import {createColumnHelper} from "@tanstack/react-table";
import {useNavigate, useRouteLoaderData} from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import Container from "../../../components/Layout/Container";
import {default as ApiCowStages} from "../../../services/UkTreatmentApi/CowStages";
import {sortBy} from "lodash";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor("id", {
        header: "ID",
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("name", {
        header: "Stage Name",
    }),
    columnHelper.accessor(value => value.retired ? "X" : "", {
        header: "Retired",
    }),
];

export async function loader() {
    const cowStages = await ApiCowStages.index().then(({data}) => sortBy(data, ["name"]));

    const getCowStage = (id) => {
        const cowStage = cowStages.find(c => c.id === parseInt(id));
        if (!cowStage) throw new Error(`Couldn't find cow stage with id \`${id}\``);

        return cowStage;
    }

    return {cowStages, getCowStage};
}

const CowStages = () => {
    const {cowStages} = useRouteLoaderData("manageCowStages");
    const navigate = useNavigate();

    return <Container>
        <DataTable title="Cow Stages" data={cowStages} columns={columns} useFilter={true}
                   onCreateClick={() => navigate("create")} onRowClick={(row) => navigate(row.id.toString())}/>
    </Container>;
}

export default CowStages;