import * as yup from "yup";
import {useFetcher, useLocation, useSubmit} from "react-router-dom";
import moment from "moment-timezone";
import {Formik} from "formik";
import FormikDatePicker from "../../../components/Input/FormikDatePicker";
import FormikTextInput from "../../../components/Input/FormikTextInput";
import BasicPrimaryButton from "../../../components/Button/BasicPrimaryButton";
import BasicErrorButton from "../../../components/Button/BasicErrorButton";
import ProductUnitInput from "../../../components/Input/ProductUnitInput";
import {useContext, useEffect} from "react";
import AlertContext from "../../../contexts/AlertContext";
import FormikSelect from "../../../components/Input/FormikSelect";

const productCategorySchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
});
const productSchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
    defaultUnit: yup.string(),
    category: productCategorySchema,
    retired: yup.boolean(),
});
const reasonCategorySchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
});
const reasonSchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
    category: reasonCategorySchema,
    retired: yup.boolean(),
});
const antibioticUsageSchema = yup.object({
    id: yup.number().positive().integer(),
    program: yup.string().required(),
    farmUid: yup.string().required(),
    cowNumber: yup.string().trim()
        .required("Cow Number is required"),
    treatmentDate: yup.number().positive().integer()
        .required("Treatment Date is required"),
    product: productSchema
        .required("Product is required"),
    dosage: yup.number().positive().integer()
        .required("Dosage is required"),
    unit: yup.string().trim()
        .required("Unit is required"),
    reason: reasonSchema.nullable().default(null),
    comments: yup.string().trim()
        .max(511, "Must be 511 characters or less"),
});

export function formDataToAntibioticUsage(formData) {
    const antibioticUsage = Object.fromEntries(formData);
    antibioticUsage.product = JSON.parse(antibioticUsage.product);
    antibioticUsage.reason = JSON.parse(antibioticUsage.reason);

    if (!antibioticUsage.reason) delete antibioticUsage.reason;

    return antibioticUsageSchema.cast(antibioticUsage);
}

const AntibioticUsageForm = ({farm, antibioticUsage, products, reasons, ...props}) => {
    const fetcher = useFetcher();
    const submit = useSubmit();
    const location = useLocation();
    const {addAlert, handleErrorAlert} = useContext(AlertContext);

    useEffect(() => {
        const result = fetcher.data?.result;
        if (result) addAlert("success", "Submission successful",
            `Added ${result.length} treatment${result.length === 1 ? "" : "s"}`);
        if (fetcher.data?.error) handleErrorAlert(fetcher.data.error, "Submission failed");
    }, [fetcher.data, addAlert, handleErrorAlert]);

    const handleDelete = event => {
        if (!window.confirm("Please confirm you want to delete")) event.preventDefault();
        else submit(null, {action: `${location.pathname}/delete`, method: "post"});
    }

    return <Formik
        initialValues={{
            program: "Antibiotic Usage",
            farmUid: farm.uid,
            treatmentDate: antibioticUsage?.treatmentDate || moment().valueOf(),
            cowNumber: antibioticUsage?.cowNumber || "",
            product: antibioticUsage?.product
                ? {
                    ...antibioticUsage.product,
                    defaultUnit: antibioticUsage.unit || antibioticUsage.product.defaultUnit || "ml"
                }
                : null,
            dosage: antibioticUsage?.dosage || 1,
            unit: antibioticUsage?.unit || "",
            reason: antibioticUsage?.reason || null,
            comments: antibioticUsage?.comments || "",
        }}
        validationSchema={antibioticUsageSchema}
        onSubmit={async values => fetcher.submit({
            ...values,
            product: JSON.stringify(values.product),
            reason: JSON.stringify(values.reason),
        }, {method: "post"})}
    >
        {formik => <form className="px-4 sm:px-6" method="post" onSubmit={formik.handleSubmit} {...props}>
            <div className="grid grid-cols-12 gap-y-6 gap-x-2 sm:gap-6">
                <div className="col-span-12 sm:col-span-6">
                    <FormikDatePicker label="Treatment Date" name="treatmentDate"/>
                </div>

                <div className="col-span-12 sm:col-span-6">
                    <FormikTextInput label="Cow Number" name="cowNumber" type="text"/>
                </div>

                <div className="col-span-12 sm:col-span-6">
                    <FormikSelect
                        name="product" label="Product" options={products} optionValue="id" optionLabel="name"
                        isClearable={false}
                    />
                </div>

                <div className="col-span-6 sm:col-span-3">
                    <FormikTextInput label="Dosage" name="dosage" type="number" min={1}/>
                </div>

                <div className="col-span-6 sm:col-span-3">
                    <ProductUnitInput label="Unit" name="unit" type="text" productValue={formik.values.product}/>
                </div>

                <div className="col-span-12">
                    <FormikSelect
                        name="reason" label="Reason" options={reasons} optionValue="id" optionLabel="name"
                        groupKey="category.name"
                    />
                </div>

                <div className="col-span-12">
                    <FormikTextInput label="Comments" name="comments" as="textarea"/>
                </div>
            </div>

            <div className="mt-4 py-3 flex justify-between">
                <BasicPrimaryButton disabled={fetcher.state === "submitting"} type="submit">
                    Submit
                </BasicPrimaryButton>

                {antibioticUsage &&
                    <BasicErrorButton onClick={handleDelete} disabled={fetcher.state === "submitting"}>
                        Delete
                    </BasicErrorButton>}
            </div>
        </form>}
    </Formik>;
}

export default AntibioticUsageForm;