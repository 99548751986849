import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import {Amplify} from "aws-amplify";
import awsExports from "./aws-export";
import {Authenticator} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {AlertProvider} from "./contexts/AlertContext";

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Authenticator variation="modal" hideSignUp={true} loginMechanisms={["email"]}>
            <AlertProvider>
                <App/>
            </AlertProvider>
        </Authenticator>
    </React.StrictMode>
);
