import FormContainer from "../../../components/Layout/FormContainer";
import FormDescription from "../../../components/Layout/FormDescription";
import AntibioticUsageForm from "./AntibioticUsageForm";

const Create = ({farm, products, reasons}) =>
    <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Antibiotic Usage" text="Register the antibiotic usage in the app."/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <AntibioticUsageForm farm={farm} products={products} reasons={reasons} id="create-antibiotic-usage-form"/>
        </div>
    </FormContainer>

export default Create;