import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from "@heroicons/react/24/outline";

const getPageNumbers = (activePage, totalPages) => {
    if (totalPages <= 1) return [1];

    const center = [activePage - 1, activePage, activePage + 1];
    const filteredCenter = center.filter(p => p > 1 && p < totalPages);
    const includeLeftDots = activePage > 3;
    const includeRightDots = activePage < totalPages - 2;

    if (includeLeftDots) filteredCenter.unshift("firstDots");
    if (includeRightDots) filteredCenter.push("lastDots");

    return [1, ...filteredCenter, totalPages];
};

const PaginationButton = (props) => <button type="button" className="inline-flex items-center justify-center
font-medium text-sm px-3 h-8 rounded-md dark:text-white hover:text-green-400 dark:hover:text-green-400
hover:bg-green-50 dark:hover:bg-gray-900" {...props}/>;

const ActivePaginationButton = (props) => <button type="button" disabled className="inline-flex items-center
justify-center font-medium text-sm px-3 h-8 rounded-md text-green-400 bg-green-50 dark:bg-gray-900" {...props}/>;

const DotsPaginationButton = (props) => <button type="button" disabled className="inline-flex items-center
justify-center font-medium text-sm px-3 h-8 rounded-md dark:text-white" {...props}/>;

const Pagination = ({activePage, totalPages, onPageChange}) => {
    const handleClick = ({action, page}) => {
        switch (action) {
            case "first":
                if (activePage !== 1) onPageChange(1);
                break;
            case "previous":
                if (activePage > 1) onPageChange(activePage - 1);
                break;
            case "next":
                if (activePage < totalPages) onPageChange(activePage + 1);
                break;
            case "last":
                if (activePage !== totalPages) onPageChange(totalPages);
                break;
            case "goto":
                if (page !== "firstDots" && page !== "lastDots") onPageChange(page);
                break;
            default:
                console.warn(`No case for action "${action}"`);
        }
    };

    return <div className="inline-flex">
        <PaginationButton onClick={() => handleClick({action: "first"})}>
            <ChevronDoubleLeftIcon className="h-3 w-3"/>
        </PaginationButton>

        <PaginationButton onClick={() => handleClick({action: "previous"})}>
            <ChevronLeftIcon className="h-3 w-3"/>
        </PaginationButton>

        {getPageNumbers(activePage, totalPages).map(pageNumber => {
                const isDots = (pageNumber === "firstDots" || pageNumber === "lastDots");
                const isActive = activePage === pageNumber;
                const Button = (props) => isDots
                    ? <DotsPaginationButton {...props}/>
                    : isActive
                        ? <ActivePaginationButton {...props}/>
                        : <PaginationButton {...props}/>;

                return <Button key={pageNumber} onClick={() => handleClick({action: "goto", page: pageNumber})}>
                    {isDots ? "..." : pageNumber}
                </Button>;
            }
        )}

        <PaginationButton onClick={() => handleClick({action: "next"})}>
            <ChevronRightIcon className="h-3 w-3"/>
        </PaginationButton>

        <PaginationButton onClick={() => handleClick({action: "last"})}>
            <ChevronDoubleRightIcon className="h-3 w-3"/>
        </PaginationButton>
    </div>;
}

export default Pagination;