import BasicPrimaryButton from "../../../components/Button/BasicPrimaryButton";
import {useNavigation, useSubmit} from "react-router-dom";
import * as yup from "yup";
import {FieldArray, Formik} from "formik";
import FormikTextInput from "../../../components/Input/FormikTextInput";
import FormikError from "../../../components/Input/FormikError";
import MultipleInputHeader from "../../../components/Input/MultipleInputHeader";
import ProductUnitInput from "../../../components/Input/ProductUnitInput";
import FormikSelect from "../../../components/Input/FormikSelect";
import FormikSelectWithCross from "../../../components/Input/FormikSelectWithCross";

const productCategorySchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
});
const productSchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
    defaultUnit: yup.string(),
    category: productCategorySchema,
    retired: yup.boolean(),
});
const treatmentSchema = yup.object({
    product: productSchema
        .required("Product is required"),
    dosage: yup.number().positive().integer()
        .required("Dosage is required"),
    unit: yup.string().trim()
        .required("Unit is required"),
});
const cowStageSchema = yup.object({
    id: yup.number().positive().integer(),
    name: yup.string(),
    retired: yup.boolean(),
});
const productPlanSchema = yup.object({
    id: yup.number().positive().integer(),
    cowStage: cowStageSchema
        .required("Cow Stage is required"),
    treatments: yup.array().of(treatmentSchema)
        .required("Treatments are required")
        .min(1, "At least one Treatment is required"),
});

export function formDataToProductPlan(formData) {
    const productPlan = Object.fromEntries(formData);
    productPlan.cowStage = JSON.parse(productPlan.cowStage);
    productPlan.treatments = JSON.parse(productPlan.treatments);

    return productPlanSchema.cast(productPlan);
}

const ProductPlansCreateForm = ({cowStages, products, ...props}) => {
    const navigation = useNavigation();
    const submit = useSubmit();

    return <Formik
        initialValues={{
            cowStage: null,
            treatments: [{
                product: null,
                dosage: 1,
                unit: "",
            }]
        }}
        validationSchema={productPlanSchema}
        onSubmit={async values => submit({
            ...values,
            cowStage: JSON.stringify(values.cowStage),
            treatments: JSON.stringify(values.treatments),
        }, {method: "post"})}
    >
        {formik => <form className="px-4 sm:px-6" method="post" onSubmit={formik.handleSubmit} {...props}>
            <div className="grid grid-cols-12 gap-y-6 gap-x-2 sm:gap-6">
                <div className="col-span-12">
                    <FormikSelect
                        name="cowStage" label="Cow Stage" options={cowStages} optionValue="id" optionLabel="name"
                        isClearable={false}
                    />
                </div>

                <div className="col-span-12">
                    <FieldArray name="treatments">
                        {({push, remove}) =>
                            <div className="grid grid-cols-12 gap-y-6 gap-x-2 sm:gap-6">
                                <div className="col-span-12">
                                    <MultipleInputHeader
                                        header="Treatments"
                                        addOnClick={() => push({
                                            product: null,
                                            dosage: 1,
                                            unit: "",
                                        })}
                                    />
                                    <FormikError name="treatments"/>
                                </div>

                                {formik.values.treatments.map((treatment, index) => [
                                    <div key={`${index}-1`} className="col-span-12 sm:col-span-6">
                                        <FormikSelectWithCross
                                            name={`treatments.${index}.product`} label="Product"
                                            groupKey="category.name"
                                            options={products} optionValue="id" optionLabel="name"
                                            onCrossClick={() => remove(index)}
                                        />
                                    </div>,
                                    <div key={`${index}-2`} className="col-span-6 sm:col-span-3">
                                        <FormikTextInput
                                            label="Dosage" name={`treatments.${index}.dosage`}
                                            type="number" min={1}
                                        />
                                    </div>,
                                    <div key={`${index}-3`} className="col-span-6 sm:col-span-3">
                                        <ProductUnitInput
                                            label="Unit" name={`treatments.${index}.unit`} type="text"
                                            productValue={formik.values.treatments[index].product}
                                        />
                                    </div>,
                                ]).flat()}
                            </div>
                        }
                    </FieldArray>
                </div>
            </div>

            <div className="mt-4 py-3 flex justify-between">
                <BasicPrimaryButton disabled={navigation.state === "submitting"} type="submit">
                    Submit
                </BasicPrimaryButton>
            </div>
        </form>}
    </Formik>;
}

export default ProductPlansCreateForm;