import {redirect, useActionData, useRouteLoaderData} from "react-router-dom";
import FormDescription from "../../../components/Layout/FormDescription";
import FormContainer from "../../../components/Layout/FormContainer";
import TreatmentReasonForm, {formDataToTreatmentReason} from "./TreatmentReasonForm";
import TreatmentReasons from "../../../services/UkTreatmentApi/TreatmentReasons";
import AlertContext from "../../../contexts/AlertContext";
import {useContext, useEffect} from "react";

export async function action({request}) {
    const formData = await request.formData();
    const treatmentReason = formDataToTreatmentReason(formData);
    treatmentReason.categoryId = treatmentReason.category.id;

    try {
        await TreatmentReasons.create(treatmentReason);

        return redirect("/manage/treatment_reasons");
    } catch (error) {
        return error;
    }
}

const Create = () => {
    const {categories} = useRouteLoaderData("manageTreatmentReasons");
    const {handleErrorAlert} = useContext(AlertContext);
    const actionError = useActionData();

    useEffect(() => {
        if (actionError) handleErrorAlert(actionError, "Failed data submission")
    }, [actionError, handleErrorAlert]);

    const text = `Register the treatment reason in the app. 
Assign the correct category.`;

    return <FormContainer>
        <div className="md:col-span-1">
            <FormDescription title="Treatment Reason" text={text}/>
        </div>

        <div className="mt-5 md:col-span-3 md:mt-0">
            <TreatmentReasonForm categories={categories} id="create-treatment-reason-form"/>
        </div>
    </FormContainer>;
}

export default Create;