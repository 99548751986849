import BasicPrimaryButton from "../../../components/Button/BasicPrimaryButton";
import {useLocation, useNavigation, useSubmit} from "react-router-dom";
import BasicErrorButton from "../../../components/Button/BasicErrorButton";
import * as yup from "yup";
import {Formik} from "formik";
import FormikTextInput from "../../../components/Input/FormikTextInput";
import {useRef} from "react";
import FormikSelect from "../../../components/Input/FormikSelect";

const userSchema = yup.object({
    sub: yup.string(),
    name: yup.string(),
    email: yup.string(),
});
const farmSchema = yup.object({
    uid: yup.string().trim()
        .required("UID is required")
        .max(255, "Must be 255 characters or less"),
    name: yup.string().trim()
        .required("Name is required")
        .max(255, "Must be 255 characters or less"),
    address: yup.string().trim()
        .required("Address is required")
        .max(255, "Must be 255 characters or less"),
    users: yup.array().of(userSchema),
    imported: yup.boolean(),
});

export function formDataToFarm(formData) {
    const farm = Object.fromEntries(formData);
    farm.users = JSON.parse(farm.users);

    return farmSchema.cast(farm);
}

const FarmForm = ({farm, users, ...props}) => {
    const navigation = useNavigation();
    const submit = useSubmit();
    const location = useLocation();

    const formRef = useRef();

    const handleDelete = event => {
        if (!window.confirm("Please confirm you want to delete")) event.preventDefault();
        else submit(null, {action: `${location.pathname}/delete`, method: "post"});
    }

    return <Formik
        initialValues={{
            uid: farm?.uid || "",
            name: farm?.name || "",
            address: farm?.address || "",
            users: farm?.users || [],
            imported: farm?.imported || false,
        }}
        validationSchema={farmSchema}
        onSubmit={async values => submit({...values, users: JSON.stringify(values.users)}, {method: "post"})}
    >
        {formik => <form className="px-4 sm:px-6" method="post" ref={formRef} onSubmit={formik.handleSubmit} {...props}>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-4">
                    <FormikTextInput label="UID" name="uid" type="text" disabled={farm}/>
                </div>

                <div className="col-span-12 sm:col-span-8">
                    <FormikTextInput label="Farm Name" name="name" type="text" disabled={farm?.imported}/>
                </div>

                <div className="col-span-12">
                    <FormikTextInput label="Address" name="address" type="text" disabled={farm?.imported}/>
                </div>

                <div className="col-span-12">
                    <FormikSelect
                        name="users" label="Users" isMulti options={users} optionValue="sub" optionLabel="name"
                    />
                </div>
            </div>

            <div className="mt-4 py-3 flex justify-between">
                <BasicPrimaryButton disabled={navigation.state === "submitting"} type="submit">
                    Submit
                </BasicPrimaryButton>

                {farm &&
                    <BasicErrorButton onClick={handleDelete} disabled={navigation.state === "submitting"}>
                        Delete
                    </BasicErrorButton>}
            </div>
        </form>}
    </Formik>;
}

export default FarmForm;