import {Link} from "react-router-dom";

const DesktopNavItem = ({item}) =>
    <Link
        to={item.to}
        className="px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:bg-green-50 hover:text-gray-800
        dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white"
    >
        {item.name}
    </Link>

export default DesktopNavItem;