import {createContext, useCallback, useState} from "react";

const AlertContext = createContext(null);
AlertContext.displayName = "AlertContext";

let _ID = 0;

export const AlertProvider = ({children}) => {
    const [alerts, setAlerts] = useState([]);
    const deleteAlert = useCallback(id => setAlerts(alerts => alerts.filter(m => m.id !== id)), []);
    const addAlert = useCallback((status, title, description) => {
        const id = _ID++;
        setAlerts(alerts => [...alerts, {id, status, title, description}]);
        setTimeout(() => deleteAlert(id), 10000);
    }, [deleteAlert]);
    const handleErrorAlert = useCallback((error, title) => {
        console.log(error);

        if (error.response) addAlert("error", title, error.response.data.message || error.response.data);
        else if (error.request) console.log(error.request);
        else addAlert("error", title, error.message);
    }, [addAlert]);

    return <AlertContext.Provider value={{alerts, addAlert, deleteAlert, handleErrorAlert}} children={children}/>
}

export default AlertContext;