import * as yup from "yup";
import {useLocation, useNavigation, useSubmit} from "react-router-dom";
import {Formik} from "formik";
import FormikTextInput from "../../../components/Input/FormikTextInput";
import BasicPrimaryButton from "../../../components/Button/BasicPrimaryButton";
import BasicErrorButton from "../../../components/Button/BasicErrorButton";
import FormikDatePicker from "../../../components/Input/FormikDatePicker";
import moment from "moment-timezone";

const fertilityPerformanceSchema = yup.object({
    id: yup.number().positive().integer(),
    farmUid: yup.string().required(),
    scanningDate: yup.number().positive().integer()
        .required("Scanning Date is required"),
    nCowsScanned: yup.number().integer().min(0)
        .required("No. Cows Scanned is required"),
    nCowsPregnant: yup.number().integer().min(0)
        .required("No. Cows Pregnant is required"),
    reason: yup.string().trim()
        .max(511, "Must be 511 characters or less"),
});

export function formDataToFertilityPerformance(formData) {
    const fertilityPerformance = Object.fromEntries(formData);

    return fertilityPerformanceSchema.cast(fertilityPerformance);
}

const FertilityPerformanceForm = ({farm, fertilityPerformance, ...props}) => {
    const navigation = useNavigation();
    const submit = useSubmit();
    const location = useLocation();

    const handleDelete = event => {
        if (!window.confirm("Please confirm you want to delete")) event.preventDefault();
        else submit(null, {action: `${location.pathname}/delete`, method: "post"});
    }

    return <Formik
        initialValues={{
            farmUid: farm.uid,
            scanningDate: fertilityPerformance?.scanningDate || moment().valueOf(),
            nCowsScanned: fertilityPerformance?.nCowsScanned || 0,
            nCowsPregnant: fertilityPerformance?.nCowsPregnant || 0,
            reason: fertilityPerformance?.reason || "",
        }}
        validationSchema={fertilityPerformanceSchema}
        onSubmit={async values => submit(values, {method: "post"})}
    >
        {formik => <form className="px-4 sm:px-6" method="post" onSubmit={formik.handleSubmit} {...props}>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-4">
                    <FormikDatePicker label="Scanning Date" name="scanningDate"/>
                </div>

                <div className="col-span-12 sm:col-span-4">
                    <FormikTextInput label="No. Cows Scanned" name="nCowsScanned" type="number" min={0}/>
                </div>

                <div className="col-span-12 sm:col-span-4">
                    <FormikTextInput label="No. Cows Pregnant" name="nCowsPregnant" type="number" min={0}/>
                </div>

                <div className="col-span-12">
                    <FormikTextInput label="Reason Empty Cows" name="reason" as="textarea"/>
                </div>
            </div>

            <div className="mt-4 py-3 flex justify-between">
                <BasicPrimaryButton disabled={navigation.state === "submitting"} type="submit">
                    Submit
                </BasicPrimaryButton>

                {fertilityPerformance &&
                    <BasicErrorButton onClick={handleDelete} disabled={navigation.state === "submitting"}>
                        Delete
                    </BasicErrorButton>}
            </div>
        </form>}
    </Formik>;
}

export default FertilityPerformanceForm;