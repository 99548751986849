import {createColumnHelper} from "@tanstack/react-table";
import {useLoaderData} from "react-router-dom";
import Container from "../../../components/Layout/Container";
import DataTable from "../../../components/DataTable/DataTable";
import moment from "moment-timezone";
import {default as ApiTreatments} from "../../../services/UkTreatmentApi/Treatments";
import Products from "../../../services/UkTreatmentApi/Products";
import TreatmentReasons from "../../../services/UkTreatmentApi/TreatmentReasons";
import {orderBy} from "lodash";
import CowStages from "../../../services/UkTreatmentApi/CowStages";

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor("program", {
        header: "Program",
    }),
    columnHelper.accessor("cowNumber", {
        header: "Cow Number",
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor(value => moment(value.treatmentDate).format("YYYY-MM-DD"), {
        header: "Treatment Date",
        sortingFn: "datetime"
    }),
    columnHelper.accessor("product.name", {
        header: "Product",
    }),
    columnHelper.accessor("dosage", {
        header: "Dosage",
        cell: info => `${info.getValue()} ${info.row.original.unit}`,
        sortingFn: "alphanumeric",
    }),
    columnHelper.accessor(value => value.cowStage?.name || value.reason?.name, {
        header: "Cow Stage/Reason",
    }),
    columnHelper.accessor("comments", {
        header: "Comments",
    }),
];

export async function loader({params}) {
    let [treatments, products, reasons, stages] = await Promise.all([
        ApiTreatments.ofFarm(params.farmUid).then(({data}) => data),
        Products.index().then(({data}) => data),
        TreatmentReasons.index().then(({data}) => data),
        CowStages.index().then(({data}) => data),
    ]);

    treatments = orderBy(
        treatments.map(t => ({
            ...t,
            product: products.find(p => p.id === t.productId),
            reason: reasons.find(r => r.id === t.reasonId),
            cowStage: stages.find(s => s.id === t.cowStageId),
        })),
        ["treatmentDate", "cowNumber", "product.name"],
        ["desc", "asc", "desc"]
    );

    return {treatments};
}

const Treatments = () => {
    const {treatments} = useLoaderData();

    return <Container>
        <DataTable title="Treatments" data={treatments} columns={columns} useFilter/>
    </Container>;
}

export default Treatments;